import { Login } from "./pages/Login";
import { LeftMenu } from "./components/LeftMenu";
import { Register } from "./pages/Register/main";
import { CarRegister } from "./pages/CarRegister/main";
import { CarList } from "./pages/CarList/main";
import { CarDetail } from "./pages/CarDetail/main";

import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import styled from "styled-components";
import { UsageDetail } from "./pages/UsageDetail/main";
import { UserList } from "./pages/UserList/main";
import { UserDetail } from "./pages/UserDetail/main";
import { UserExaminationList } from "./pages/UserExamination/main";
import { ConfirmMail } from "./pages/confirm";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { reduxStateType } from "./reduxReducer";
import { useEffect } from "react";
import { BaseURL } from "./setting";
import axios from "axios";
import { CarEdit } from "./pages/CarEdit/main";
// import { api } from "./api";
import { Top } from "./pages/Top/main";
// import { MailRegister } from "./pages/Register/MailRegister";
import { ThemeProvider } from 'styled-components'
import * as color from "./utils/color";
import ImageComponent from "./components/ImageComponent";

// const Layout = () => (
//   <>
//     <Header />
//     <Outlet />
//   </>
// );

const Layout = () => {
  // 前提。後で実装する。
  // これはグローバル変数である必要がある。
  // ログインに成功したらクッキーにセッションを残す。
  // const [isLogined, setIsLogined] = useState(false);
  const isLogin = useSelector(
    (state: reduxStateType) => state.isLogin,
    shallowEqual
  );
  // const isLogin = true
  const disptch = useDispatch();

  useEffect(() => {
    console.log("ログインされているか");
    console.log(isLogin);
    if (isLogin) return;
    console.log("ログインされていなかったあとの世界。");
    // もしログイン状態でない（グローバル変数で）ならば、。。。という前提のもとで。
    let accessToken = localStorage.getItem("accessToken");
    // アクセストークンが有効だったら、そのままログインさせる。
    if (!!accessToken) {
    }
    (async () => {
      await axios
        .post(BaseURL + "auth/token/verify/", { token: accessToken })
        .then(() => {
          console.log("verify成功");
          disptch({ type: "Login.SetLogin" });
        })
        .catch(() => {
          console.log("verify失敗");
          // リフレッシュトークンを用いる？
          let refreshToken = localStorage.getItem("refreshToken");
          axios
            .post(BaseURL + "auth/token/refresh/", { refresh: refreshToken })
            .then((refreshResponse: any) => {
              // console.log("リレッシュ成功")
              // console.log(refreshResponse.data.access)
              localStorage.setItem("accessToken", refreshResponse.data.access);
              // console.log(refreshResponse.data.refresh)
              localStorage.setItem(
                "refreshToken",
                refreshResponse.data.refresh
              );
              disptch({ type: "Login.SetLogin" });
            })
            .catch((err) => {
              console.log(err);
            });
        });
    })();
    // クッキーの中でアクセストークンが残っているかをチェック。
    // アクセストークンが有効かどうかをチェック。

    // ログインしたときはアクセストークンを
    // setIsLogined(true)
  }, []);
  // ログインされているかをチェック
  if (isLogin !== true) {
    console.log("ログインされていないのでログインページにリダイレクトします。");
    // console.log(localStorage)
    return <Login />;
  }

  return (
    <Wrapper>
      {/* ここにログインしているかどうかをいれる。
       */}
      <LeftMenu />
      <Outlet />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  min-height:100vh;
`;


const router = createBrowserRouter([
  {
    children: [
      {
        path: "/login/",
        element: <Login />,
        // loader: authLoader
      },
      {
        path: "/register/",
        element: <Register />,
        // loader: authLoader
      },
      {
        path: "/confirm_email/",
        element: <ConfirmMail />,
        // loader: authLoader
      },

      {
        path: "/sign_up_confirm/",
        element: <ConfirmMail />,
        // loader: authLoader
      },
      

      {
        path: "/",
        element: <Layout />,
        // element: ,
        children: [
          {
            path: "",
            element: <Top />,
          },
          {
            path: "/image/:imgURL",
            element: <ImageComponent />,
          },
          {
            path: "cars/",
            element: <CarList />,
          },
          {
            path: "cars/:carId",
            element: <CarDetail />,
          },
          {
            path: "cars/:carId/edit",
            element: <CarEdit />,
          },
          {
            path: "usages/:reservationId",
            element: <UsageDetail />,
          },
          {
            path: "cars/register",
            element: <CarRegister />,
          },
          {
            path: "users/",
            element: <UserList />,
          },
          {
            path: "users/:userId",
            element: <UserDetail />,
          },
          {
            path: "/examination/users/",
            element: <UserExaminationList />,
          },
        ],
      },
    ],
  },
]);

const theme = {
  colors: {
    Black: color.Black,
    Gray2: color.Gray2,
    Gray6: color.Gray6,
    CancelColor: color.CanselColor,
    AgreeColor: color.AgreeColor,
    // 他のカスタム色もここに追加できます
  }
}

function App() {
  return (
    <><ThemeProvider theme={{theme}}>
      <RouterProvider router={router} />
      </ThemeProvider>
    </>
  );
}

export default App;
