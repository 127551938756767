import { useEffect, useState } from "react";
import styled from "styled-components";
import { api, ReqAndRes } from "../../api";
import { CommonButton } from "../../utils/Button";
import dayjs from "dayjs";
import * as color from "../../utils/color";
// import RotateImage,{SimpleImage} from "../../components/RotateImage";
import { Link } from "react-router-dom";

export const UserExaminationList = () => {
  const [examinationUserData, setExaminationUserData] = useState<
    ReqAndRes["GET users/examination_users/"]["res"]["data"]["results"]
  >([]);


  const getExaminationList = async () => {
    // 0が審査前、1が審査許可、-1が審査拒否。審査前だけを表示
    api("GET users/examination_users/", {is_examinated:0})
      .then((res) => {
        setExaminationUserData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const accept = (useId: number) => {
    // 承認のAPIを送る。この引数としては、
    (async () => {
      await api("POST users/judge/", { id: useId, examination: 1 })
        .then((res) => {
          console.log("成功");
          getExaminationList();
        })
        .catch((err) => {
          console.log("失敗");
          getExaminationList();
        });
    })();
  };
  const deny = (useId: number) => {
    // 承認のAPIを送る。この引数としては、
    (async () => {
      await api("POST users/judge/", { id: useId, examination: -1 })
        .then((res) => {
          console.log("成功");
          getExaminationList();
        })
        .catch((err) => {
          console.log("失敗");
          getExaminationList();
        });
    })();
  };

  useEffect(() => {
    getExaminationList();
  }, []);

  return (
    <div>
      <div>
        <h2>登録審査(過去10件まで表示)</h2>
        <UserItemWrapperHeader>
          <ApplicationDateField>申請日</ApplicationDateField>
          <UserNameField>名前</UserNameField>
          <UserAddressField>住所</UserAddressField>
          <UserPhoneField>連絡先</UserPhoneField>
          <ExaminationInfomationField>免許情報</ExaminationInfomationField>
          <div>審査可否</div>
        </UserItemWrapperHeader>
        {examinationUserData.map((v, index) => {
          return (
            <UserItemWrapper key={index}>
              <ApplicationDateField>
                {dayjs(v.createdAt).format("YYYY-MM-DD")}
              </ApplicationDateField>
              <UserNameField>{v.firstName + " " + v.lastName}</UserNameField>
              <UserAddressField>
                {v.prefecture}
                {v.address1}
                {v.address2}
              </UserAddressField>
              <UserPhoneField>{v.phoneNumber}<br/>{v.mail}</UserPhoneField>
              {/* <UserPlanField>{planName[v.plan!]}</UserPlanField> */}
              <ExaminationInfomationField>
                事故歴:{v.hasAccidentHistory ? "あり" : "なし"}　/　違反歴:
                {v.hasPenaltyHistory ? "あり" : "なし"}
                <br />
                <br />
                有効期限{v.licenseValidDate}
                <br />
                {/* <ImageRotateContainer> */}
                {/* <RotateImage
                  src={
                    "https://storage.forest-sapporo-car-sharing.com/" +
                    v.licenseImage
                  }
                  alt="免許証画像"
                /> */}
                <Link to={"/image/"+ v.licenseImage}><CommonButton padding="2px" width="120px;">免許証画像を表示</CommonButton></Link>
                {/* </ImageRotateContainer> */}
              </ExaminationInfomationField>
              {v.isExaminated === 0 && (
                <ButtonWrapper>
                  <CommonButton margin="5px;" onClick={() => accept(v.id)}>
                    承諾
                  </CommonButton>
                  <CommonButton margin="5px;" onClick={() => deny(v.id)}>
                    拒否
                  </CommonButton>
                </ButtonWrapper>
              )}
              {v.isExaminated === 1 && "可決"}
              {v.isExaminated === -1 && "否決"}
            </UserItemWrapper>
          );
        })}
      </div>
      <div>
        <h2>退会審査</h2>
      </div>
      なし
    </div>
  );
};

// const ImageRotateContainer = styled.div`
//   position: relative;
//   width: 100%;
//   padding-top: 100%; /* 1:1 Aspect Ratio */
//   transform: rotate(90deg); /* Rotate container to fit the image */
//   overflow: hidden;
// `;

// const RotateImage = styled.img`
//  position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;
//   object-fit: cover;
//   transform: rotate(-90deg); 
//   `

const UserItemWrapper = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px ${color.Gray4};
  padding: 10px;
`;

const UserItemWrapperHeader = styled(UserItemWrapper)`
background-color: ${color.Black};
color: ${color.White};
font-weight: bold;
`

const ApplicationDateField = styled.div`
  width: 100px;
`;
const UserNameField = styled.div`
  width: 100px;
`;

const UserAddressField = styled.div`
  width: 250px;
`;
const UserPhoneField = styled.div`
  width: 150px;
`;
const ExaminationInfomationField = styled.div`
  width: 250px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
