import dayjs from "dayjs";

type inputStringType = {
  value?: string;
  validation?: string[];
};
type inputNumberType = {
  value?: number;
  validation?: string[];
};

// タイプ。
type carStateType = {
  name?: inputStringType;
  grade?: inputStringType;
  parking?: inputStringType;
  specification?: inputStringType;
  size?: inputStringType;
  carNumber?: inputStringType;
  carInspectionCertificate?: inputStringType;
  needpoint?: inputNumberType;
  registrationDateOfVehicleInspection?: inputStringType;
  ownerPhoneNumber?: inputStringType;
  insuranceLicenseNumber?: inputStringType;
  insuranceCompanyName?: inputStringType;
  insuranceCompanyContactName?: inputStringType;
  insuranceCompanyTel?: inputStringType;
  keyvoxNumber?: inputStringType;
  keyvoxPlace?: inputStringType;
  thumbnail?: inputStringType;
  numberPlate?: inputStringType;
  // 前
  bodyPicutreFront?: inputStringType;
  // 左
  bodyPicutreLeft?: inputStringType;
  // 右
  bodyPicutreRight?: inputStringType;
  // 後ろ
  bodyPicutreBack?: inputStringType;
  // 前左
  bodyPicutreFrontLeft?: inputStringType;
  // 前右
  bodyPicutreFrontRight?: inputStringType;
  // 後ろ左
  bodyPicutreBackLeft?: inputStringType;
  // 後ろ右
  bodyPicutreBackRight?: inputStringType;
};
// 編集画面用
type carType = {
  name?: string;
  grade?: string;
  parking?: string;
  specification?: string;
  size?: string;
  carNumber?: string;
  carInspectionCertificate?: string;
  needpoint?: number;
  registrationDateOfVehicleInspection?: string;
  ownerPhoneNumber?: string;
  insuranceLicenseNumber?: string;
  insuranceCompanyName?: string;
  insuranceCompanyContactName?: string;
  insuranceCompanyTel?: string;
  keyvoxNumber?: string;
  keyvoxPlace?: string;
  thumbnail?: string;
  numberPlate?: string;
  // 前
  bodyPicutreFront?: string;
  // 左
  bodyPicutreLeft?: string;
  // 右
  bodyPicutreRight?: string;
  // 後ろ
  bodyPicutreBack?: string;
  // 前左
  bodyPicutreFrontLeft?: string;
  // 前右
  bodyPicutreFrontRight?: string;
  // 後ろ左
  bodyPicutreBackLeft?: string;
  // 後ろ右
  bodyPicutreBackRight?: string;
};

const initailInput = {
  value: "",
  validation: [],
};

// 初期値
export const initialCarState: carStateType = {
  name: initailInput,
  grade: initailInput,
  parking: initailInput,
  numberPlate: initailInput,
  specification: initailInput,
  size: initailInput,
  carNumber: initailInput,
  carInspectionCertificate: initailInput,
  needpoint: { value: 0, validation: [] },
  registrationDateOfVehicleInspection: {
    value: dayjs().format("YYYY-MM-DD"),
    validation: [],
  },
  ownerPhoneNumber: initailInput,
  keyvoxNumber: initailInput,
  thumbnail: initailInput,
  bodyPicutreFront: initailInput,
  bodyPicutreLeft: initailInput,
  bodyPicutreRight: initailInput,
  bodyPicutreBack: initailInput,
  bodyPicutreFrontLeft: initailInput,
  bodyPicutreFrontRight: initailInput,
  bodyPicutreBackLeft: initailInput,
  bodyPicutreBackRight: initailInput,
  insuranceLicenseNumber: initailInput,
  insuranceCompanyTel: initailInput,
  insuranceCompanyName: initailInput,
  insuranceCompanyContactName: initailInput,
  keyvoxPlace: initailInput,
};

// アクションの集合。VSでのコード補完、置き換えの関係もありこうしている。
export enum CarRegisterActionType {
  ACTION_INPUT_NAME = "ACTION_INPUT_NAME",
  ACTION_INPUT_GRADE = "ACTION_INPUT_GRADE",
  ACTION_INPUT_PARKING = "ACTION_INPUT_PARKING",
  ACTION_INPUT_NUMBER_PLATE = "ACTION_INPUT_NUMBER_PLATE",
  ACTION_INPUT_SPECIFICATION = "ACTION_INPUT_SPECIFICATION",
  ACTION_INPUT_SIZE = "ACTION_INPUT_SIZE",
  ACTION_INPUT_CAR_NUMBER = "ACTION_INPUT_CAR_NUMBER",
  ACTION_INPUT_CAR_INSPECTION_CERTIFICATE = "ACTION_INPUT_CAR_INSPECTION_CERTIFICATE",
  ACTION_INPUT_NEED_POINT = "ACTION_INPUT_NEED_POINT",
  ACTION_INPUT_REGISTRATION_DATE_OF_VEICLE_INSPECTION = "ACTION_INPUT_REGISTRATION_DATE_OF_VEICLE_INSPECTION",
  ACTION_INPUT_OWNER_PHONE_NUMBER = "ACTION_INPUT_OWNER_PHONE_NUMBER",
  ACTION_INPUT_KEYVOX_NUMBER = "ACTION_INPUT_KEYVOX_NUMBER",
  ACTION_INPUT_KEYVOX_PLACE = "ACTION_INPUT_KEYVOX_PLACE",
  ACTION_INPUT_THUMNAIL = "ACTION_INPUT_THUMNAIL",
  ACTION_INPUT_BODY_PICTURE_FRONT = "ACTION_INPUT_BODY_PICTURE_FRONT",
  ACTION_INPUT_BODY_PICTURE_LEFT = "ACTION_INPUT_BODY_PICTURE_LEFT",
  ACTION_INPUT_BODY_PICTURE_RIGHT = "ACTION_INPUT_BODY_PICTURE_RIGHT",
  ACTION_INPUT_BODY_PICTURE_BACK = "ACTION_INPUT_BODY_PICTURE_BACK",
  ACTION_INPUT_BODY_PICTURE_FRONT_LEFT = "ACTION_INPUT_BODY_PICTURE_FRONT_LEFT",
  ACTION_INPUT_BODY_PICTURE_FRONT_RIGHT = "ACTION_INPUT_BODY_PICTURE_FRONT_RIGHT",
  ACTION_INPUT_BODY_PICTURE_BACK_LEFT = "ACTION_INPUT_BODY_PICTURE_BACK_LEFT",
  ACTION_INPUT_BODY_PICTURE_BACK_RIGHT = "ACTION_INPUT_BODY_PICTURE_BACK_RIGHT",
  ACTION_INPUT_INSURANCE_LICENSE_NUMBER = "ACTION_INPUT_INSURANCE_LICENSE_NUMBER",
  ACTION_INPUT_INSURANCE_COMPANY_TEL = "ACTION_INPUT_INSURANCE_COMPANY_TEL",
  ACTION_INPUT_INSURANCE_COMPANY_NAME = "ACTION_INPUT_INSURANCE_COMPANY_NAME",
  ACTION_INPUT_INSURANCE_COMPANY_CONTACT_NAME = "ACTION_INPUT_INSURANCE_COMPANY_CONTACT_NAME",
  ACTION_INITIALIZE = "ACTION_INITIALIZE",
}

export type CarRegisterPayloadType = {
  inputValue?: string;
  inputNumberValue?: number;
  initialData?: carType;
};

export type CarRegisterAction = {
  type: CarRegisterActionType;
  payload: CarRegisterPayloadType;
};

export const RegisterReducer: React.Reducer<carStateType, CarRegisterAction> = (
  state,
  action
) => {
  switch (action.type) {
    case CarRegisterActionType.ACTION_INPUT_NAME: {
      return {
        ...state,
        name: { value: action.payload.inputValue!, validation: [] },
      };
    }
    case CarRegisterActionType.ACTION_INPUT_GRADE: {
      return {
        ...state,
        grade: { value: action.payload.inputValue!, validation: [] },
      };
    }
    case CarRegisterActionType.ACTION_INPUT_PARKING: {
      return {
        ...state,
        parking: { value: action.payload.inputValue!, validation: [] },
      };
    }
    case CarRegisterActionType.ACTION_INPUT_SPECIFICATION: {
      return {
        ...state,
        specification: { value: action.payload.inputValue!, validation: [] },
      };
    }
    case CarRegisterActionType.ACTION_INPUT_SIZE: {
      return {
        ...state,
        size: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_NUMBER_PLATE: {
      return {
        ...state,
        numberPlate: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_CAR_NUMBER: {
      return {
        ...state,
        carNumber: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_CAR_INSPECTION_CERTIFICATE: {
      return {
        ...state,
        carInspectionCertificate: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_NEED_POINT: {
      return {
        ...state,
        needpoint: { value: action.payload.inputNumberValue!, validation: [] },
      };
    }
    case CarRegisterActionType.ACTION_INPUT_REGISTRATION_DATE_OF_VEICLE_INSPECTION: {
      return {
        ...state,
        registrationDateOfVehicleInspection: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_OWNER_PHONE_NUMBER: {
      return {
        ...state,
        ownerPhoneNumber: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_KEYVOX_NUMBER: {
      return {
        ...state,
        keyvoxNumber: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_KEYVOX_PLACE: {
      return {
        ...state,
        keyvoxPlace: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_THUMNAIL: {
      return {
        ...state,
        thumbnail: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_FRONT: {
      return {
        ...state,
        bodyPicutreFront: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_LEFT: {
      return {
        ...state,
        bodyPicutreLeft: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_RIGHT: {
      return {
        ...state,
        bodyPicutreRight: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_BACK: {
      return {
        ...state,
        bodyPicutreBack: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_FRONT_LEFT: {
      return {
        ...state,
        bodyPicutreFrontLeft: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_FRONT_RIGHT: {
      return {
        ...state,
        bodyPicutreFrontRight: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_BACK_LEFT: {
      return {
        ...state,
        bodyPicutreBackLeft: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_BACK_RIGHT: {
      return {
        ...state,
        bodyPicutreBackRight: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }
    case CarRegisterActionType.ACTION_INPUT_INSURANCE_COMPANY_TEL: {
      return {
        ...state,
        insuranceCompanyTel: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }

    case CarRegisterActionType.ACTION_INPUT_INSURANCE_LICENSE_NUMBER: {
      return {
        ...state,
        insuranceLicenseNumber: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }
    case CarRegisterActionType.ACTION_INPUT_INSURANCE_COMPANY_NAME: {
      return {
        ...state,
        insuranceCompanyName: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }
    case CarRegisterActionType.ACTION_INPUT_INSURANCE_COMPANY_CONTACT_NAME: {
      return {
        ...state,
        insuranceCompanyContactName: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }

    // 編集画面における初期化。
    case CarRegisterActionType.ACTION_INITIALIZE: {
      return {
        ...state,

        name: { value: action.payload.initialData?.name, validation: [] },
        grade: { value: action.payload.initialData?.grade, validation: [] },
        parking: { value: action.payload.initialData?.parking, validation: [] },
        specification: { value: action.payload.initialData?.specification, validation: [] },
        size: { value: action.payload.initialData?.size, validation: [] },
        numberPlate: { value: action.payload.initialData?.numberPlate, validation: [] },
        carNumber: { value: action.payload.initialData?.carNumber, validation: [] },
        carInspectionCertificate: {
          value: action.payload.initialData?.carInspectionCertificate,
          validation: [],
        },
        needpoint: { value: action.payload.initialData?.needpoint, validation: [] },
        registrationDateOfVehicleInspection: {
          value: action.payload.initialData?.registrationDateOfVehicleInspection,
          validation: [],
        },
        ownerPhoneNumber: {
          value: action.payload.initialData?.ownerPhoneNumber,
          validation: [],
        },
        keyvoxNumber: { value: action.payload.initialData?.keyvoxNumber, validation: [] },
        keyvoxPlace: { value: action.payload.initialData?.keyvoxPlace, validation: [] },
        thumbnail: { value: action.payload.initialData?.thumbnail, validation: [] },
        bodyPicutreFront: { value: action.payload.initialData?.bodyPicutreFront, validation: [] },
        bodyPicutreLeft: { value: action.payload.initialData?.bodyPicutreLeft, validation: [] },
        bodyPicutreRight: { value: action.payload.initialData?.bodyPicutreRight, validation: [] },
        bodyPicutreBack: { value: action.payload.initialData?.bodyPicutreBack, validation: [] },
        bodyPicutreFrontLeft: {
          value: action.payload.initialData?.bodyPicutreFrontLeft,
          validation: [],
        },
        bodyPicutreFrontRight: {
          value: action.payload.initialData?.bodyPicutreFrontRight,
          validation: [],
        },
        bodyPicutreBackLeft: {
          value: action.payload.initialData?.bodyPicutreBackLeft,
          validation: [],
        },
        bodyPicutreBackRight: {
          value: action.payload.initialData?.bodyPicutreBackRight,
          validation: [],
        },
        insuranceCompanyTel: {
          value: action.payload.initialData?.insuranceCompanyTel,
          validation: [],
        },
        insuranceLicenseNumber: {
          value: action.payload.initialData?.insuranceLicenseNumber,
          validation: [],
        },
        insuranceCompanyName: {
          value: action.payload.initialData?.insuranceCompanyName,
          validation: [],
        },
        insuranceCompanyContactName: {
          value: action.payload.initialData?.insuranceCompanyContactName,
          validation: [],
        },
      };
    }

    default:
      return initialCarState;
  }
};
