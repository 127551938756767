import styled from "styled-components";
import * as color from "../utils/color";

type selectProp = {
  value: string | number;
  label: string;
};
export const CommonSelect = ({
  selectProps,
  name,
  onChange = () => {},
  width,
  isCansel = false,
  value
}: {
  selectProps: selectProp[];
  name?: string;
  selectValue?: string | number;
  onChange?: (e: any) => void;
  width?: string;
  isCansel?: boolean;
  value?:any
}) => {
  return (
    <StyledSelect
      value={value}
      name={name}
      onChange={(e) => {
        onChange!(e);
      }}
      width={width}
      isCansel={isCansel}
    >
      {selectProps.map((selectProp: selectProp, index) => {
        return (
          <option key={index} value={selectProp.value}>
            {selectProp.label}
          </option>
        );
      })}
    </StyledSelect>
  );
};

const StyledSelect = styled.select<{ width?: string; isCansel?: boolean }>`
  ${({ width }) => `width:${width};`}
  ${({ isCansel }) =>
    isCansel
      ? `background-color: ${color.CanselColor};`
      : `background-color: ${color.Gray2};`}
color:${color.Black};
padding: 10px;
  border: 0px;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  display:flex ;
  justify-content:center ;
`;
