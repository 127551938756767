
export const DAYWIDTH = 40;
// 一度に表示する日付の数
export const DISPLAYUNIT = 7;

export type ScheduleComponentType = {
  start: any;
  endOfUse: any;
  isOpen: number;
  type?: string;
};

export type usageCarInfoType = {
  start: string;
  end: string;
  utillzationTime: number;
};


// カレンダー用の時間
export const TIMELABELS = [
  "0:00-2:00",
  "2:00-4:00",
  "4:00-6:00",
  "6:00-8:00",
  "8:00-10:00",
  "10:00-12:00",
  "12:00-14:00",
  "14:00-16:00",
  "16:00-18:00",
  "18:00-20:00",
  "20:00-22:00",
  "22:00-24:00",
];

export const selectTimeData = [
  { label: "2時間", value: 2 },
  { label: "4時間", value: 4 },
  { label: "6時間", value: 6 },
  { label: "8時間", value: 8 },
  { label: "10時間", value: 10 },
];