import styled from "styled-components";
// 素材
import { api, ReqAndRes } from "../../api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ImageURL } from "../../setting";
import { dayjsDatetime } from "../../utils/dayjsFormatter";
import { BadNews } from "../../components/BadNews";



export const UsageDetail = () => {
  const [data, setData] = useState<
    | ReqAndRes["RETRIEVE usages/reservations-for-owner/"]["res"]["data"]
    | undefined
  >();
  const [returnData, setReturnData] = useState<
    | ReqAndRes["RETRIEVE usages/reservations-for-owner/"]["res"]["data"]["usages"][0]
    | undefined
  >();
  const [usageData, setUsageData] = useState<
    | ReqAndRes["RETRIEVE usages/reservations-for-owner/"]["res"]["data"]["usages"][0]
    | undefined
  >();
  // const [badNewsReports,setBadNewsReports] = useState<ReqAndRes["RETRIEVE usages/reservations-for-owner/"]["res"]["data"]["badNewsReports"]>([])
  let { reservationId } = useParams();

  const getReservation = async () => {
    await api("RETRIEVE usages/reservations-for-owner/", {
      id: Number(reservationId!),
    })
      .then((res) => {
        console.log(res);
        setData(res.data);
        if (res.data.usages && res.data.usages!.length > 0) {
          setUsageData(res.data.usages[0]);
        }
        if (res.data.usages && res.data.usages!.length > 1) {
          setReturnData(res.data.usages[1]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // const 
  useEffect(() => {
    (getReservation)();
  }, []);

  return (
    <div>
      <h1>
        利用詳細 {data?.user?.fullName}/{data?.car?.name}
      </h1>
      {/* <div>利用者：{data?.user?.fullName}</div> */}
      <h2>予約</h2>
      <div>
        開始時刻：
        {dayjsDatetime(data?.startReservationDateTime!)}
        /終了時刻：
        {dayjsDatetime(data?.endReservationDateTime!)}
      </div>
      <h2>利用</h2>
      {!!usageData ? (
        <div>
          貸出時刻：{dayjsDatetime(usageData.createdAt!)}
        </div>
      ) : (
        <div>貸出時刻：まだ貸し出しされていません</div>
      )}
      {/* <div>延長予約：{data.exptentionRental}</div> */}
      {!!returnData ? (
        <div>
          返却時間{dayjsDatetime(returnData.createdAt!)}
        </div>
      ) : (
        <div>返却時刻：まだ返却されていません</div>
      )}
      {usageData && (
        <>
          <h2>貸出前の画像</h2>
          <UsageImages images={usageData.images[0]!} />
        </>
      )}
      {!!returnData && (
        <>
          <hr />
          <h2>返却時(貸出後)の画像</h2>
          <UsageImages images={returnData.images[0]!} />
        </>
      )}
      <hr />
      <h2>違反報告</h2>
      {!!data && <BadNews badnews={data.badNewsReports!} reloadAfter={getReservation}/>}
      <hr />
      <h2>傷報告</h2>
      <DamageReport damages={returnData?.damageReports!} />
      <DamageReport damages={usageData?.damageReports!} />
      <h2>免許証</h2>
      {!!usageData && <ImageItem src={ImageURL + usageData!.licenseImage} alt="" />}
      <hr />
    </div>
  );
};


const DamageReport = ({
  damages,
}: {
  damages: ReqAndRes["RETRIEVE usages/reservations-for-owner/"]["res"]["data"]["usages"][0]["damageReports"];
}) => {
  return (
    <div>
      {!!damages && damages.map((v,index) => {
        return (
          <div key={index}>
            <div>{v.textContent}</div>
            <img alt={"傷画像"+index} src={ImageURL+v.damegeImage}/>
          </div>
        );
      })}
    </div>
  );
};

const UsageImages = ({
  images,
}: {
  images: ReqAndRes["RETRIEVE usages/reservations-for-owner/"]["res"]["data"]["usages"][0]["images"][0];
}) => {
  return (
    <ImageWrapper>
      <a href={ImageURL + images.frontImage}>
        {" "}
        <ImageItem src={ImageURL + images.frontImage} alt="" />
      </a>
      <a href={ImageURL + images.rightImage}>
        {" "}
        <ImageItem src={ImageURL + images.rightImage} alt="" />
      </a>
      <a href={ImageURL + images.leftImage}>
        {" "}
        <ImageItem src={ImageURL + images.leftImage} alt="" />
      </a>
      <a href={ImageURL + images.backImage}>
        {" "}
        <ImageItem src={ImageURL + images.backImage} alt="" />
      </a>
      <a href={ImageURL + images.frontLeftImage}>
        {" "}
        <ImageItem src={ImageURL + images.frontLeftImage} alt="" />
      </a>
      <a href={ImageURL + images.frontRightImage}>
        {" "}
        <ImageItem src={ImageURL + images.frontRightImage} alt="" />
      </a>
      <a href={ImageURL + images.backLeftImage}>
        {" "}
        <ImageItem src={ImageURL + images.backLeftImage} alt="" />
      </a>
      <a href={ImageURL + images.backRightimage}>
        {" "}
        <ImageItem src={ImageURL + images.backRightimage} alt="" />
      </a>
    </ImageWrapper>
  );
};

const ImageWrapper = styled.div`
  display: flex;
`;
const ImageItem = styled.img`
  width: 150px;
  /* height:200px; */
  margin-left: 10px;
`;
