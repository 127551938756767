import { useEffect, useState } from "react"
import { api } from "../api"

export const ConfirmMail = ()=>{
    // URLのパラメータを取り出す。キーの部分。
    // それを受け取って、特定のapiを叩くレスポンスを返す
    const [loading,setLoading]=useState(true)
    const [error,setError]=useState(false)
    // クエリパラメータの入手
    const queryParameters = new URLSearchParams(window.location.search)
    const key = queryParameters.get("key")
    useEffect(
        ()=>{
            console.log(key);
            (async ()=>{
                await api("POST auth/register/verify-email/",{key:key!}).then(
                    (res)=>{
                        console.log("認証成功")
                    }
                ).catch(
                    (err)=>{console.log(err)
                    setError(true)
                    }
                ).finally(
                    ()=>{
                        setLoading(false)
                    }
                )
            })()
        },[]
    )
    if (loading) return <>ローディング</>
    if (error) return <>すでに認証済みか、メール認証の期限が切れています。</>
    return <>メール認証が完了しました。</>
}