// ここにAPIを書いていく。
// loginからかく？
// トークンの確認もいる。
import axios from "axios";
import { BaseURL } from "./setting";
// type Auth = {
//   "POST auth/register/":{
//     req:{
      
//     }
//   }
// }

type ApartmentOwnerAuthType = {
  "POST auth/owner-login/": {
    req: {
      email: string;
      password: string;
    };
    res: {
      data: {
        accessToken: string;
        refreshToken: string;
      };
    };
  };
  //メールのキーの確認
  "POST auth/register/verify-email/": {
    req: {
      key: string;
    };
    res: {
      data: {
        datail: string;
      };
    };
  };
  "POST users/register/owner/": {
    req: {
      email: string;
      password: string;
      apartmentName: string;
      apartmentAddress: string;
      companyName: string;
      companyAddress: string;
      companyPresidentName: string;
      companyManagerName: string;
      phoneNumber: string;
      bankName: string;
      bankBranchName: string;
      bankAccountsNumber: number;
      bankAccountsType: string;
    };
    res: {
      data: {
        datail: string;
      };
    };
  };
  "GET users/owner/me/": {
    req: null;
    res: { data: { apartmentName: string,id:string } };
  };
};

type S3Type = {
  "GET s3/get-presigned-url/": {
    req: null;
    res: {
      data: {
        url: string;
        path: string;
      };
    };
  };
};

type ExaminationUserType = {
  "GET users/examination_users/": {
    req: {is_examinated?:number}|null;
    res: {
      data: {
        count: number;
        next: string;
        before: string;
        results: {
          id: number;
          createdAt: string;
          updatedAt: string;
          lastName: string;
          firstName: string;
          lastNameKana: string;
          firstNameKana: string;
          dateOfBirth: string;
          prefecture: string;
          address1: string;
          address2: string;
          phoneNumber: string;
          hasAccidentHistory: boolean;
          hasPenaltyHistory: boolean;
          licenseImage: string;
          licenseValidDate: string;
          plan: string;
          mail: string;
          isExaminated: number,
        }[];
      };
    };
  };

  "POST users/judge/": {
    req: {
      id: number;
      examination: number;
    };
    res: null;
  };
};

type RegidenceUserType = {
  "GET users/owner/residence_users/": {
    req: null;
    res: {
      data: {
        count: number;
        next: string;
        before: string;
        results: {
          id: string;
          plan: string;
          dateJoined: string;
          lastPoint: number;
          fullName: string;
        }[];
      };
    };
  };
  "RETRIEVE users/owner/residence_users/": {
    req: { id: string };
    res: {
      data: {
        id: string;
        plan: string;
        fullName: string;
        dateJoined: string;
        lastPoint: number;
        address1: string;
        address2: string;
        phoneNumber: string;
        licenseImage: string;
        licenseValidDate: string;
        mail: string;
        dateOfBirth: string | number;
        reservations: {
          id: number;
          createdAt: string;
          updatedAt: string;
          startReservationDateTime: string;
          endReservationDateTime: string;
          useTime: number;
          status: number;
          pointId: number;
          originReservationId: number;
          isPenalty: boolean;
          car: {
            id: number;
            name: string;
            parkingAddress: string;
          };
          user: {
            id: string;
            plan: string;
            fullName: string;
            dateJoined: string;
            lastPoint: number;
          };
        }[];
        badNewsReports: {
          id?: number;
          createdAt?: string;
          updatedAt?: string;
          type?: string;
          accidentAt?: string;
          contentDetail?: string;
          placeDetail?: string;
          managerName?: string;
          managerPhoneNumber?: string;
          isOwnerCheck?: boolean;
          isReturn?: boolean;
          car?: number;
          user?: string;
          reservation?: number;
        }[];
      };
    };
  };
};
type carType = {
  "POST cars/": {
    req: {
      name?: string;
      grade?: string;
      parkingAddress?: string;
      specipication?: string;
      size?: string;
      carNo?: string;
      // 車検証写真パス
      carInspectionCertificate?: string;
      needPoint?: number;
      isDraft?: boolean;
      registerDateOfCarInspection?: string;
      numberPlate?: string;
      ownerTel?: string;
      insuranceLicenseNo?: string;
      keyBoxPlace?: string;
      keyBoxNo?: string;
      insuranceCompanyTel?: string;
      frontImage?: string;
      leftImage?: string;
      rightImage?: string;
      backImage?: string;
      frontLeftImage?: string;
      frontRightImage?: string;
      backLeftImage?: string;
      backRightImage?: string;
      thumbnailImage?: string;
    };
    res: {
      data: {
        id: number;
        createdAt: string;
        updatedAt: string;
        name: string;
        grade: string;
        parkingAddress: string;
        specipication: string;
        size: string;
        carNo: string;
        carInspectionCertificate: string;
        needPoint: string;
        isDraft: true;
        registerDateOfCarInspection: string;
        numberPlate: string;
        ownerTel: string;
        insuranceLicenseNo: string;
        keyBoxPlace: string;
        keyBoxNo: string;
        insuranceCompanyTel: string;
        frontImage: string;
        leftImage: string;
        rightImage: string;
        backImage: string;
        frontLeftImage: string;
        frontRightImage: string;
        backLeftImage: string;
        backRightImage: string;
        thumbnailImage: string;
        owner: string;
      };
    };
  };
  "PATCH cars/": {
    req: {
      id?: number;
      name?: string;
      grade?: string;
      parkingAddress?: string;
      specipication?: string;
      size?: string;
      carNo?: string;
      // 車検証写真パス
      carInspectionCertificate?: string;
      needPoint?: number;
      isDraft?: boolean;
      registerDateOfCarInspection?: string;
      numberPlate?: string;
      ownerTel?: string;
      insuranceLicenseNo?: string;
      keyBoxPlace?: string;
      keyBoxNo?: string;
      insuranceCompanyTel?: string;
      frontImage?: string;
      leftImage?: string;
      rightImage?: string;
      backImage?: string;
      frontLeftImage?: string;
      frontRightImage?: string;
      backLeftImage?: string;
      backRightImage?: string;
      thumbnailImage?: string;
    };
    res: {
      data: {
        id: number;
        createdAt: string;
        updatedAt: string;
        name: string;
        grade: string;
        parkingAddress: string;
        specipication: string;
        size: string;
        carNo: string;
        carInspectionCertificate: string;
        needPoint: string;
        isDraft: true;
        registerDateOfCarInspection: string;
        numberPlate: string;
        ownerTel: string;
        insuranceLicenseNo: string;
        keyBoxPlace: string;
        keyBoxNo: string;
        insuranceCompanyTel: string;
        frontImage: string;
        leftImage: string;
        rightImage: string;
        backImage: string;
        frontLeftImage: string;
        frontRightImage: string;
        backLeftImage: string;
        backRightImage: string;
        thumbnailImage: string;
        owner: string;
      };
    };
  };
  "GET cars/": {
    req: {
      limit?: number;
      offset?: number;
    } | null;
    res: {
      data: {
        count: number;
        next?: string;
        previous?: string;
        results: {
          id?: number;
          createdAt?: string;
          updatedAt?: string;
          name?: string;
          grade?: string;
          parkingAddress?: string;
          specipication?: string;
          size?: string;
          carNo?: string;
          carInspectionCertificate?: string;
          needPoint?: string;
          isDraft?: true;
          registerDateOfCarInspection?: string;
          numberPlate?: string;
          ownerTel?: string;
          insuranceLicenseNo?: string;
          keyBoxPlace?: string;
          keyBoxNo?: string;
          insuranceCompanyTel?: string;
          frontImage?: string;
          leftImage?: string;
          rightImage?: string;
          backImage?: string;
          frontLeftImage?: string;
          frontRightImage?: string;
          backLeftImage?: string;
          backRightImage?: string;
          thumbnailImage?: string;
          owner?: string;
          isAvailable?: string;
        }[];
      };
    };
  };

  "RETRIEVE cars/": {
    req: {
      id: number;
    };
    res: {
      data: {
        id?: number;
        createdAt: string;
        updatedAt: string;
        name?: string;
        grade?: string;
        parkingAddress?: string;
        specipication?: string;
        size?: string;
        carNo?: string;
        carInspectionCertificate?: string;
        registerDateOfCarInspection?: string;
        needPoint?: number;
        isDraft?: true;
        numberPlate?: string;
        ownerTel?: string;
        insuranceLicenseNo?: string;
        keyBoxPlace?: string;
        keyBoxNo?: string;
        insuranceCompanyTel?: string;
        insuranceCompanyName?: string;
        insuranceCompanyContactName?: string;
        frontImage?: string;
        leftImage?: string;
        rightImage?: string;
        backImage?: string;
        frontLeftImage?: string;
        frontRightImage?: string;
        backLeftImage?: string;
        backRightImage?: string;
        thumbnailImage?: string;
        owner?: string;
        reservations: {
          id?: number;
          car?: {
            id?: number;
            name?: string;
            parkingAddress?: string;
          };
          user?: {
            id?: string;
            plan?: string;
            fullName?: string;
            dateJoined?: string;
            lastPoint?: number;
          };
          createdAt?: string;
          updatedAt?: string;
          startReservationDateTime?: string;
          endReservationDateTime?: string;
          useTime?: number;
          status?: number;
          isPenalty?: boolean;
          point?: number;
          originReservation?: number;
        }[];
        damageReports: {
          id: number;
          createdAt: string;
          updatedAt: string;
          textContent: string;
          damegeImage: string;
          isChecked: boolean;
          user: {
            id: string;
            plan: string;
            fullName: string;
            dateJoined: string;
            lastPoint: string;
          };
        }[];
      };
    };
  };

  "GET cars/not_available_span/": {
    req: {
      car?: string | number;
      limit?: number;
      offset?: number;
    } | null;
    res: {
      data: {
        count: number;
        next?: string;
        previous?: string;
        results: {
          id: number;
          start: string;
          end: string;
          type: string;
          // "isValid": true,
        }[];
      };
    };
  };

  "POST cars/not_available_span/": {
    req: {
      start: string;
      end: string;
      type: string;
      car: number;
    };
    res: {
      start: string;
      end: string;
      type: string;
      car: number;
    };
  };

  // 使うのだけ持ってきた。
  "PATCH cars/not_available_span/": {
    req: {
      id: number | string;
      isValid: boolean;
    };
    res: {};
  };
  "GET cars/schedule/": {
    req: {
      id: number;
    };
    res: {
      data: { start: string; end: string; type?: string }[];
    };
  };
};

type UsageType = {
  // 暗証番号の発行。レスポンスはなし
  "PATCH usages/bad-news/for-owner/":{
    req:{id:number,isOwnerCheck:boolean}
    res:{
      data:any
    }
  }

  "POST usages/keyvoxs/owner_make_pin_id/": {
    // car_id
    req: { id: number };
    res: {
      data: string;
    };
  };
  "GET usages/keyvoxs/owner_make_pin_id/get_pass_code/": {
    // car_id
    req: { car: number };
    res: {
      data: { passCode: string };
    };
  };
  "GET usages/bad-news/for-owner/": {
    // car_id
    req: { car__id?: number; reservation__id?: number,is_owner_check?:boolean };
    res: {
      data: {
        results: {
          id: number;
          createdAt: string;
          updatedAt: string;
          type: string;
          accidentAt: string;
          contentDetail?: string;
          placeDetail?: string;
          managerName?: string;
          managerPhoneNumber?: string;
          isOwnerCheck: boolean;
          isReturn: boolean;
          car: number;
          user: string;
          reservation: number;
        }[];
      };
    };
  };

  "RETRIEVE usages/reservations-for-owner/": {
    req: { id: number };
    res: {
      data: {
        id?: number;
        car?: {
          id?: number;
          name?: string;
          parkingAddress?: string;
        };
        user?: {
          id?: string;
          plan?: string;
          fullName?: string;
          dateJoined?: string;
          lastPoint?: number;
        };
        usages: {
          id?: number;
          images: {
            id?: number;
            createdAt?: string;
            updatedAt?: string;
            frontImage?: string;
            leftImage?: string;
            rightImage?: string;
            backImage?: string;
            frontLeftImage?: string;
            frontRightImage?: string;
            backLeftImage?: string;
            backRightimage?: string;
            car?: number;
            user?: string;
            usage?: number;
          }[];
          damageReports: {
            id: number;
            createdAt: string;
            updatedAt: string;
            textContent: string;
            damegeImage: string;
            isChecked: boolean;
            car: number;
            user: string;
            usage: number;
          }[];
          createdAt?: string;
          updatedAt?: string;
          usageType?: string;
          licenseImage?: string;
          user?: string;
          car?: string;
          reservation?: number;
        }[];
        badNewsReports: {
          id?: number;
          createdAt?: string;
          updatedAt?: string;
          type?: string;
          accidentAt?: string;
          contentDetail?: string;
          placeDetail?: string;
          managerName?: string;
          managerPhoneNumber?: string;
          isOwnerCheck?: boolean;
          isReturn?: boolean;
          car?: number;
          user?: string;
          reservation?: number;
        }[];
        createdAt?: string;
        updatedAt?: string;
        startReservationDateTime?: string;
        endReservationDateTime?: string;
        useTime?: number;
        status?: number;
        isPenalty?: boolean;
        point?: number;
        originReservation?: number;
      };
    };
  };
};

type PointType = {
  "POST points/":{
    req:{
      pointDiff:number,
      user:string,
    }
    res:{
      data:{
        pointDiff:number,
        user:string
      }
    }
  }
}

export type ReqAndRes = ApartmentOwnerAuthType &
  S3Type &
  carType &
  ExaminationUserType &
  RegidenceUserType &
  UsageType & PointType;

export function api<K extends keyof ReqAndRes>(
  key: K,
  payload: ReqAndRes[K]["req"]
): Promise<ReqAndRes[K]["res"]> {
  // なにもない場合はnullっぽい
  const accessToken = localStorage.getItem("accessToken");
  const headers = {
    Authorization: `jwt ${accessToken}`,
  };
  const config = {
    headers: headers,
  };

  // console.log(`ローカルストレージの実験${test}`)
  // どこからかとってくる必要がある
  // 　headers={}
  // const queryDetail = useSelector((state) => state.queryDetail, shallowEqual);
  const [method, path] = key.split(" ");
  // メソッドもパスもない場合。
  if (!method || !path) {
    throw new Error(`Unrecognized api: ${key}`);
  }
  const option: RequestInit = { method };
  const url = BaseURL + path;
  // let header;
  let pathWithID = "";

  //   ここらへんは後で書く。
  switch (option.method) {
    case "GET":
      if (path === "s3/get-presigned-url/") {
        return axios.get(BaseURL + path, { params: payload });
      }
      break;

    // 後ほどコメントアウト外す。
    case "DELETE":
      if (payload && "id" in payload) {
        pathWithID = `${path}${payload.id}/`;
      }
      option.headers = { "Content-Type": "application/json" };
      return axios.delete(BaseURL + pathWithID, config);

    case "RETRIEVE":
      if (payload && "id" in payload) {
        pathWithID = `${path}${payload.id}/`;
      }
      return axios.get(BaseURL + pathWithID, config);

    case "POST":
      // 登録周りだからauthしてはいけない。ここに書くべきではないと思う。
      if (
        path === "auth/owner-login/" ||
        path === "auth/register/verify-email/" ||
        path === "users/register/owner/"
      ) {
        return axios.post(BaseURL + path, payload);
      }
      // option.body = JSON.stringify(payload);
      return axios.post(BaseURL + path, payload, config);

    case "PATCH":
      if (payload && "id" in payload) {
        pathWithID = `${path}${payload.id}/`;
      }
      //   option.body = JSON.stringify(payload);
      //パッチの場合
      return axios.patch(BaseURL + pathWithID, payload, config);
  }

  return axios.get(url, {
    headers: headers,
    params: payload,
  });

  //   getパラメータをpayloadで制御できるようにした。
}
