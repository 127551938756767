import { useState } from "react";
import styled from "styled-components";
import { useRef } from "react";
import {api} from "../api"
import axios from "axios";

export const CommonFile = ({setS3Path}:{
    setS3Path?:(v:any)=>any,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File>();

  const onChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputImage = event.target.files; // inputImageは、この時点でFileList | null
    if (inputImage != null) {
      setImage(event.target.files![0]);
    }
    ;(async ()=>{
        api("GET s3/get-presigned-url/",null).then(
            (res)=>{
                axios({
                    method: "PUT",
                    //   url: url+filename,
                    url: res.data.url,
                    data: event.target.files![0]!,
                    headers: {"Content-type":"image/jpg",}
                  })
                    .then((r) => {
                      console.log(r);
                      console.log("アップロードを行いました。");
                      setS3Path?.(res.data.path)
                    })
                    .catch((err) => {
                      console.log(err);
                      console.log("アップロードに失敗");
                    });



            }
        ).catch(
            (err)=>{
                console.log(err)
            }
        )
    })()

  };

  const onClickButton = () => {
    inputRef.current?.click();
  };
  return (
    <>
      <label>
        <input type="file" accept="image/*"  ref={inputRef} hidden onChange={onChangeImage}/>
      </label>
      <button onClick={onClickButton}>選択する</button>
      {!!image ? <p>{image.name}</p>:<p></p>}
    </>
  );
};
