import { ImageURL } from "../../setting"
import { ReqAndRes } from "../../api"

export const CarInfo = ({car}:{car:ReqAndRes["RETRIEVE cars/"]["res"]["data"]})=>{
    return <div>
        {!!car && <>
            <div>グレード：{car.grade}</div>
        <div>利用ポイント{car.needPoint}</div>
       <div>駐車場所：{car.parkingAddress}</div>
       <div>型式(仕様)：{car.specipication}</div>
       <div>サイズ：{car.size}</div>
       <div>車体番号：{car.carNo}</div>
       <div>車検登録日{car.registerDateOfCarInspection}</div>
       
       <div>ナンバープレート：{car.numberPlate}</div>
       <div>契約保険会社：{car.insuranceCompanyName}</div>
       <div>保険契約者名：{car.insuranceCompanyContactName}</div>
       <div>保険証保険番号：{car.insuranceLicenseNo}</div>
       <div>保険会社電話番号：{car.insuranceCompanyTel}</div>
       <div>保険証写真：<a href={ImageURL+car.carInspectionCertificate} target="blank">クリックして表示</a></div>
       <div>オーナー電話番号：{car.ownerTel}</div>
       <div>キーボックスの番号：{car.keyBoxNo}</div>
       <div>キーボックスの場所：{car.keyBoxPlace}</div>
       </>}
    </div>
}