// export const BaseURL = "http://localhost:8008/"
export const BaseURL = "https://api.forest-sapporo-car-sharing.com:444/"
export const ImageURL = "https://storage.forest-sapporo-car-sharing.com/"

export const GradeSelect = [
    { label: "グレードを選択してください　", value: 1 },
    { label: "Sクラス", value: "Sクラス" },
    { label: "Aクラス", value: "Aクラス" },
    { label: "Nクラス", value: "Nクラス" },
    { label: "その他", value: "その他" },
  ];