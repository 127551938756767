import styled from "styled-components";
import * as color from "./color";

export const CommonTextField = ({ onChange,id,value,label,variant,type,width,margin,placeholder }: { onChange?: (e: any) => void ,id?:string,value?:any,label?:any,variant?:any,type?:any,width?:any,margin?:any,placeholder?:any}) => {
  // 利用方法としては、上の階層で状態管理しているものをvalueに指定し、valueを司るものをonChangeに指定する
  const textChange = (e:any)=>{
    onChange?.(e)
  }
  return <Input onChange={textChange} id={id!} value={value} type={type} width={width} margin={margin} placeholder={placeholder}/>;
};


export const CommonTextArea = ({ onChange,id,value,label,variant,type,defaultValue }: { onChange?: (e: any) => void ,id?:string,value?:any,label?:any,variant?:any,type?:any,defaultValue?:string}) => {
  // 利用方法としては、上の階層で状態管理しているものをvalueに指定し、valueを司るものをonChangeに指定する
  const textChange = (e:any)=>{
    onChange!(e)
  }
  return <Area onChange={textChange} id={id!} value={value}/>;
};


const Input =styled.input<{width?:string,margin?:string}>`
height:40px;
/* border:0px ; */
padding:0px ;
border-top:0px ;
border-left:0px ;
border-right:0px ;
border-bottom:0px solid ${color.Gray4};
background-color:${color.Gray1} ;
width:100%;
font-size: 16px;
${({width})=> width ? `width:${width};`:`width:100%;`}
  ${({margin})=> margin ? `margin:${margin};`:`;`}
/* margin-right:10px; */
`
const Area=styled.textarea`
height:60px;
/* border:0px ; */
padding:0px ;
border-top:0px ;
border-left:0px ;
border-right:0px ;
border: 1px solid ${color.Gray4};
background-color:${color.Gray1} ;
border-radius:5px ;
width:100%;
`