import styled from "styled-components";
import { ReactNode } from "react";
import * as color from "./color"

export const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  justify-content: left;
  text-align: left;
  align-items: center;
`;

export const RowTitle = styled.div`
  /* min-width: 80px; */
  /* test用 */
  display: flex;
  justify-content: left;
  /* width:100%; */
  width:200px;
  /* margin-right: 8px; */
`;
export const RowContent = styled.div`
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: left;
  /* test用 */
  /* border: solid 1px; */
`;

export const Container = ({ children }: { children: ReactNode }) => {
  return (
    <ContainerWrapper>
      <ContainerWraped>{children}</ContainerWraped>
    </ContainerWrapper>
  );
};

export const ContainerWraped = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 95%;
`;

const ContainerWrapper = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width:100%;
  height:100%;
  margin:10px ;
`;

export const CameraImageWrapper = styled.div`
`

export const CameraImage= styled.img`
transform: rotate(90deg);
margin-top:-50px ;
margin-bottom:-50px ;
aspect-ratio: 9 / 16;
width:180px;
/* height:300px; */
`

export const Title = styled.div`
font-weight:500;
font-size:24px;
`

export const Content = styled.div`
border:solid 1px ${color.Gray2};
border-radius:5px ;
padding:5px;
margin:10px;
min-height:50px;
width:100%;
`