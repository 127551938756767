import styled from "styled-components";
import { CommonButton, CancelButton } from "../../utils/Button";
import { CommonTextField } from "../../utils/TextField";
import * as color from "../../utils/color";
import { useParams } from "react-router-dom";
import { api, ReqAndRes } from "../../api";
import { useState, useEffect } from "react";
import { ImageURL } from "../../setting";
import { Link } from "react-router-dom";
import { dayjsDatetime, dayjsDate } from "../../utils/dayjsFormatter";
import { BadNews } from "../../components/BadNews";
import { planName } from "../../utils/typeAndConfig";

export const UserDetail = () => {
  let { userId } = useParams();
  const [data, setData] =
    useState<ReqAndRes["RETRIEVE users/owner/residence_users/"]["res"]["data"]>();
  const [point, setPoint] = useState<number>();

  const getUserData = async () => {
    await api("RETRIEVE users/owner/residence_users/", { id: String(userId) })
      .then((res) => {
        console.log(res);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addPoint = () => {
    (async () => {
      await api("POST points/", { user: userId!, pointDiff: point! })
        .then((res) => {
          console.log("成功");
          getUserData();
          setPoint(undefined);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  };
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <UserDetailWrapper>
      <h1>{data?.fullName}</h1>
      <h3>現在のポイント：{data?.lastPoint}ポイント</h3>
      <ContentTopWrapper>
        <CommonTextField
          type={"number"}
          width={"150px"}
          margin={"5px;"}
          value={point}
          placeholder={"数字を入力"}
          onChange={(e) => setPoint(e.target.value)}
        />{" "}
        ポイントを
        <CommonButton onClick={addPoint} width={"100px;"} margin={"5px;"}>
          付与
        </CommonButton>(会員資格を停止したい場合は、マイナスのポイントを付与してください。)
        {/* <CancelButton width="100px;" margin={"5px"}>
          会員資格停止
        </CancelButton>
        <CancelButton width="100px;" margin={"5px"}>
          強制退会
        </CancelButton> */}
      </ContentTopWrapper>
      <ContentWrapper>
        <Left>
          <Content>
            <h3>基本情報</h3>
            <div>登録日：{dayjsDate(data?.dateJoined!)}</div>
            <div>会員ステータス：{planName[data?.plan!]}</div>
            <div>連絡先電話番号：{data?.phoneNumber}</div>
            <div>連絡先メールアドレス：{data?.mail}</div>
            <div>住所：{data?.address1! + data?.address2!}</div>
            <div>生年月日：{data?.dateOfBirth}</div>
            <div>免許証有効期限：({data?.licenseValidDate}：</div>
            {data?.licenseImage && (
              <Link to={"/image/"+ data?.licenseImage}><LicensePhotoImage src={ImageURL + data?.licenseImage} alt="" /></Link>
            )}
          </Content>
        </Left>
        <Right>
          <Content>
            <h3>予約履歴</h3>
            {data?.reservations.map((v, index) => {
              return (
                <Link to={"/usages/" + v.id}>
                <div key={index}>
                    <h4>{dayjsDatetime(v.startReservationDateTime!)} - {dayjsDatetime(v.endReservationDateTime!)}</h4>
                    {/* {v.car.name!} */}
                    <hr/>
                </div>
                </Link>
              );
            })}
          </Content>

          <Content>
            <h3>違反履歴</h3>
            {/* 本当だったらコンポーネント化するべき。。。 */}
            {!!data && (
              <BadNews
                badnews={data.badNewsReports}
                reloadAfter={getUserData}
              />
            )}
          </Content>
        </Right>
      </ContentWrapper>
    </UserDetailWrapper>
  );
};

const UserDetailWrapper = styled.div`
  margin: 10px;
  width: 100%;
`;

const Content = styled.div`
  border: solid 1px ${color.Gray2};
  border-radius: 5px;
  padding: 4px;
  margin: 5px;
  min-height: 50px;
`;

const ContentTopWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const Left = styled.div`
  width: 50%;
`;

const Right = styled.div`
  width: 50%;
`;

const LicensePhotoImage = styled.img`
  width: 200px;
`;
