import styled from "styled-components";
import * as color from "../utils/color";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState,useEffect } from "react";
import { api } from "../api";

export const LeftMenu = () => {
  const disptach = useDispatch()
  const logout = ()=>{
    disptach({type:"Login.SetLogout"})
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("tokenDate")
  }
  const [manshonName,setManshonName]=useState<string>("")
  useEffect(
    ()=>{
        (async ()=>{
            await api("GET users/owner/me/",null).then
            ((res)=>{
                console.log(res)
                setManshonName(res.data.apartmentName)
            }).catch(
                (err)=>{console.log(err)}
            )
        })()
    },[]
)
  
  return (
    <LeftMenuWrapper>
      {/* マンション名を取得するようにする。 */}
      <HeaderWrapper>{manshonName}</HeaderWrapper>
      <Link to="/">
        <MenuItem>トップページ</MenuItem>
      </Link>
      <Link to="/cars">
        <MenuItem>車両一覧</MenuItem>
      </Link>
      <Link to="/users">
        <MenuItem>会員ユーザ一覧</MenuItem>
      </Link>
      <Link to="/examination/users/">
        <MenuItem>審査一覧</MenuItem>
      </Link>
      <MenuItem onClick={logout}>ログアウト</MenuItem>
      {/* <MenuItem>設定</MenuItem> */}
    </LeftMenuWrapper>
  );
};
const HeaderWrapper = styled.div`
/* background-color: ${color.Gray2}; */
/* height:50px; */
width:100%;
font-size:24px;
font-weight:500;
padding:5px;
`


const MenuItem = styled.div`
  padding: 10px;
  :hover {
    background-color: ${color.Gray2};
  }
  color:${color.White};
`;

const LeftMenuWrapper = styled.div`
  /* margin: 12px; */
  padding:12px;
  min-width: 200px;
  color:${color.White};
  width: 200px;
  border-right: 2px solid ${color.Gray3};
  background-color:${color.Black} ;
  min-height: 100vh;
`;
