import styled from "styled-components";
import { CommonButton, CancelButton } from "../../utils/Button";
import { useParams } from "react-router-dom";
import { CarSchedule } from "./Schedule";
import { UseHistory } from "./UseHistory";
import { DamageHistory } from "./DamageHistory";
import { ImageCarousel } from "./ImageCarousel";
import { CarInfo } from "./CarInfo";
import { CommonTextField } from "../../utils/TextField";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { api, ReqAndRes } from "../../api";
import dayjs from "dayjs";

export const CarDetail = () => {
  let { carId } = useParams();
  const [notAvailableSpanArray, setNotAvailableSpanArray] = useState<
    ReqAndRes["GET cars/schedule/"]["res"]["data"]
  >([]);
  const [car, setCar] = useState<ReqAndRes["RETRIEVE cars/"]["res"]["data"]>();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [notAvailableSpan, setNotAvailableSpan] = useState<
    ReqAndRes["GET cars/not_available_span/"]["res"]["data"]["results"]
  >([]);
  // const addaddNotAvailableSpanAPI = async ()=>{}

  const [reservations, setReservations] = useState<
    ReqAndRes["RETRIEVE cars/"]["res"]["data"]["reservations"]
  >([]);
  const [damageReport, setdamageReport] = useState<
    ReqAndRes["RETRIEVE cars/"]["res"]["data"]["damageReports"]
  >([]);

  const [passCode, setPassCode] = useState("");

  const addNotAvailableSpan = () => {
    (async () => {
      let data = {
        start: start!,
        end: end!,
        type: "メンテナンス",
        car: Number(carId)!,
      };
      console.log(start);
      api("POST cars/not_available_span/", data)
        .then((res) => {
          getNotAvailableSpan();
        })
        .catch((err) => {});
    })();
  };
  const getNotAvailableSpan = () => {
    (async () => {
      api("GET cars/not_available_span/", { car: Number(carId) }).then(
        (res) => {
          setNotAvailableSpan(res.data.results);
        }
      );
    })();
  };

  const [isPublish, setIsPublish] = useState("");
  const publish_pass_code = () => {
    console.log("test");
    console.log(car);
    if (car?.keyBoxNo === "") {
      setIsPublish(
        "キーボックス番号が登録されていません。まずは配布したキーボックスに対応するキーボックス番号を設定してください。"
      );
      return;
    }
    (async () => {
      await api("POST usages/keyvoxs/owner_make_pin_id/", {
        id: Number(carId!),
      })
        .then((res) => {
          console.log("成功しました。");
          setIsPublish(
            "暗証番号の発行に成功しました。数分後に暗証番号を取得してください。"
          );
        })
        .catch((err) => {
          setIsPublish("暗証番号の発行に失敗しました。");
          console.log(err);
        });
    })();
  };
  const get_pass_code = () => {
    (async () => {
      await api("GET usages/keyvoxs/owner_make_pin_id/get_pass_code/", {
        car: Number(carId),
      })
        .then((res) => {
          setPassCode(res.data.passCode);
        })
        .catch((err) => {
          setPassCode(
            "暗証番号の取得に失敗しました。上の暗証番号発行ボタンを押してから数分まってから行ってください。"
          );
          console.log(err);
        });
    })();
  };

  useEffect(() => {
    // 初期の取得
    (async () => {
      api("RETRIEVE cars/", { id: Number(carId) })
        .then((res) => {
          console.log(res.data);
          setCar(res.data!);
          setReservations(res.data.reservations!);
          setdamageReport(res.data.damageReports!);
        })
        .catch(
          // ページを見れなくしてもいいまである。
          (err) => {}
        );
    })();
    getNotAvailableSpan();
  }, []);

  // 車の予定を取得
  useEffect(() => {
    (async () => {
      await api("GET cars/schedule/", { id: Number(carId!) })
        .then((res) => {
          // console.log(res.data)
          setNotAvailableSpanArray(res.data);
        })
        .catch((err) => {
          // 処理
        });
    })();
  }, [carId, notAvailableSpan]);

  const removeNoAvailableSpan = (id: number) => {
    (async () => {
      api("PATCH cars/not_available_span/", { id: id, isValid: false })
        .then((res) => {
          console.log("削除成功");
          getNotAvailableSpan();
        })
        .catch((err) => {
          console.log("削除失敗");
        });
    })();
  };

  return (
    <CarDetailWrapper>
      <Header>
        {/* {carId} */}
        <Title>{car!! && car.name}</Title>{" "}
        <Link to={"/cars/" + carId + "/edit"}>
          <CommonButton width="100px">編集する</CommonButton>
        </Link>
        {/* <CancelButton width="100px">削除</CancelButton> */}
      </Header>
      <ContentWrapper>
        <Left>
          <ImageCarousel car={car} />
          <CarInfo car={car!} />
          <Wrapper>
            <CommonButton
              width="150px"
              margin="5px"
              onClick={publish_pass_code}
              padding="2px;"
            >
              暗証番号の手動発行
            </CommonButton>
            {!isPublish ? (
              <>発行後、３分後から１時間の間で取得・利用できます。</>
            ) : (
              <>{isPublish}</>
            )}
          </Wrapper>
          <Wrapper>
            <CommonButton
              width="150px"
              margin="5px"
              padding="2px;"
              onClick={get_pass_code}
            >
              暗証番号の手動取得
            </CommonButton>
            {passCode}
          </Wrapper>
          <Wrapper>
            {/* 後できれいにする。 */}
            <h3>メンテナンス(貸し出し不可)期間の追加</h3>
            <MentenanceWrapper>
              貸出不可開始日
              <CommonTextField
                onChange={(e: any) => {
                  setStart(e.target.value);
                  // setEnd(e.target.value);
                }}
                type="datetime-local"
                // width="220px"
              />
              <br />
              貸出不可終了日
              <CommonTextField
                onChange={(e: any) => {
                  setEnd(e.target.value);
                }}
                type="datetime-local"
                // width="220px"
              />
            </MentenanceWrapper>
            <ButtonWrapper>
              <CommonButton
                width="100px"
                margin="10px"
                onClick={addNotAvailableSpan}
                disabled={!start || !end}
              >
                追加
              </CommonButton>
            </ButtonWrapper>
            {/* <CommonButton width="100px" margin="10px">貸出不可の復元</CommonButton> */}
          </Wrapper>
          <Wrapper>
            <div>
              <h3>貸し出し不可期間一覧</h3>
              {notAvailableSpan.map((v, index) => {
                return (
                  <p key={index}>
                    {v.type + "："}
                    {dayjs(v.start).format("YYYY-MM-DD HH:mm")}　-　
                    {dayjs(v.end).format("YYYY-MM-DD HH:mm")}{" "}
                    <button
                      onClick={() => {
                        removeNoAvailableSpan(v.id);
                      }}
                    >
                      取り消す
                    </button>
                  </p>
                );
              })}
            </div>
          </Wrapper>

          {/* <Wrapper>
            <div>
              メンテナンス開始日
              <CommonTextField width="150px"/>
              メンテナンス終了日
              <CommonTextField width="150px"/>
            </div>
            <CommonButton width="200px" margin="10px">メンテナンス期間の登録</CommonButton>
          </Wrapper> */}
        </Left>
        <Right>
          <CarSchedule
            onClick={(usageCarInfo: any) => {}}
            isLate={false}
            notAvailableSpanArray={notAvailableSpanArray}
          />
          <UseHistory reservations={reservations} />
          <DamageHistory damageData={damageReport} />
        </Right>
      </ContentWrapper>
    </CarDetailWrapper>
  );
};

const MentenanceWrapper = styled.div`
width: 100%;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CarDetailWrapper = styled.div`
  padding: 5px;
  width: 900px;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content:space-between ;
  /* border: solid 1px; */
`;

const Right = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px; */
`;
const Left = styled.div`
  width: 60%;
  display: flex;
  margin-right: 10px;
  flex-direction: column;
  /* border: solid 1px; */
`;

const Wrapper = styled.div`
  padding: 5px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
`;
