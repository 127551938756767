import { useState, useReducer } from "react";
import styled from "styled-components";
import { CommonButton as _CommonButton } from "../../utils/Button";
import { Row, RowTitle, RowContent, Container } from "../../utils/Common";
import { RadioGroup } from "../../utils/Radio";
import { CommonTextField } from "../../utils/TextField";
import {
  RegisterReducer,
  inistialRegisterState,
  RegisterActionType,
  RegisterStateType
} from "../../reducer/registerReducer";
import { api } from "../../api";

export const Register = () => {
  const [mode, setMode] = useState(1);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [registerState, registerDispatch] = useReducer(
    RegisterReducer,
    inistialRegisterState
  );
  const valudationAndNext = () => {
    // ここにバリデーション関数が入る
    setMode(2);
  };

  let registerCheckSum = 0;
  (Object.keys(registerState) as (keyof RegisterStateType)[]).forEach(
    (v) => {
      if(!!registerState[v].value) {registerCheckSum=registerCheckSum+1}
    }
  )

  const register = () => {
    (async () => {
      await api("POST users/register/owner/", {
        email: registerState.mailAddress.value!,
        password: registerState.password.value!,
        apartmentName: registerState.manshonName.value!,
        apartmentAddress: registerState.manshonAddress.value!,
        companyName: registerState.companyName.value!,
        companyAddress: registerState.companyAddress.value!,
        companyPresidentName: registerState.companyPresidentName.value!,
        companyManagerName: registerState.companyManagerName.value!,
        phoneNumber: registerState.phoneNumber.value!,
        bankName: registerState.bankName.value!,
        bankBranchName: registerState.bankBranchName.value!,
        bankAccountsType: registerState.bankType.value!,
        bankAccountsNumber: registerState.bankNumber.value!,
      })
        .then((res) => {
          console.log("成功");
          setSuccess(true);
        })
        .catch((err) => {
          console.log(err);
          // 失敗した場合は、きりかわらない
          setError(true);
        });
    })();
  };

  const bankTypeCharacter = (bankType: string) => {
    switch (bankType) {
      case "1":
        return "普通";
      case "2":
        return "当座";
      case "3":
        return "預金";

      default:
        return "エラー";
    }
  };

  if (success) return <>登録が完了しました。メールを確認して認証を行ってください。</>

  if (mode === 1) {
    return (
      <Container>
        <Row>
          {/* onblurで確認することにする。 時間があれば使えるものかどうか確認する*/}
          <RowTitle>メールアドレス</RowTitle>
          <RowContent>
            <CommonTextField
              value={registerState.mailAddress.value}
              onChange={(e) =>
                registerDispatch({
                  type: RegisterActionType.ACTION_INPUT_MAIL_ADDRESS,
                  payload: { inputValue: e.target.value },
                })
              }
            />
          </RowContent>
        </Row>
        <Row>
          {/* onblurで確認することにする。 */}
          <RowTitle>パスワード</RowTitle>
          <RowContent>
            <CommonTextField
              value={registerState.password.value}
              type="password"
              onChange={(e) =>
                registerDispatch({
                  type: RegisterActionType.ACTION_INPUT_PASSWORD,
                  payload: { inputValue: e.target.value },
                })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>マンション名</RowTitle>
          <RowContent>
            <CommonTextField
              value={registerState.manshonName.value}
              onChange={(e) =>
                registerDispatch({
                  type: RegisterActionType.ACTION_INPUT_MANSHON_NAME,
                  payload: { inputValue: e.target.value },
                })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>マンション住所</RowTitle>
          <RowContent>
            <CommonTextField
              value={registerState.manshonAddress.value}
              onChange={(e) =>
                registerDispatch({
                  type: RegisterActionType.ACTION_INPUT_MANSHON_ADDRESS,
                  payload: { inputValue: e.target.value },
                })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>法人名</RowTitle>
          <RowContent>
            <CommonTextField
              value={registerState.companyName.value}
              onChange={(e) =>
                registerDispatch({
                  type: RegisterActionType.ACTION_INPUT_COMPANY_NAME,
                  payload: { inputValue: e.target.value },
                })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>法人住所</RowTitle>
          <RowContent>
            <CommonTextField
              value={registerState.companyAddress.value}
              onChange={(e) =>
                registerDispatch({
                  type: RegisterActionType.ACTION_INPUT_COMPANY_ADDRESS,
                  payload: { inputValue: e.target.value },
                })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>代表者氏名</RowTitle>
          <RowContent>
            <CommonTextField
              value={registerState.companyPresidentName.value}
              onChange={(e) =>
                registerDispatch({
                  type: RegisterActionType.ACTION_INPUT_COMPANY_PRESIDENT_NAME,
                  payload: { inputValue: e.target.value },
                })
              }
            />
          </RowContent>
        </Row>

        <Row>
          <RowTitle>担当者氏名</RowTitle>
          <RowContent>
            <CommonTextField
              value={registerState.companyManagerName.value}
              onChange={(e) =>
                registerDispatch({
                  type: RegisterActionType.ACTION_INPUT_COMPANY_MANAGER_NAME,
                  payload: { inputValue: e.target.value },
                })
              }
            />
          </RowContent>
        </Row>

        <Row>
          <RowTitle>連絡先携帯番号</RowTitle>
          <RowContent>
            <CommonTextField
              value={registerState.phoneNumber.value}
              onChange={(e) =>
                registerDispatch({
                  type: RegisterActionType.ACTION_INPUT_PHONE_NUMBER,
                  payload: { inputValue: e.target.value },
                })
              }
            />
          </RowContent>
        </Row>

        <Row>
          <RowTitle>銀行名(お支払用)</RowTitle>
          <RowContent>
            <CommonTextField
              value={registerState.bankName.value}
              onChange={(e) =>
                registerDispatch({
                  type: RegisterActionType.ACTION_INPUT_BANK_NAME,
                  payload: { inputValue: e.target.value },
                })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>支店名(お支払用))</RowTitle>
          <RowContent>
            <CommonTextField
              value={registerState.bankBranchName.value}
              onChange={(e) =>
                registerDispatch({
                  type: RegisterActionType.ACTION_INPUT_BANK_BRANCH_NAME,
                  payload: { inputValue: e.target.value },
                })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>種類(お支払用)</RowTitle>
          <RowContent>
            <RadioGroup
              radioProps={[
                { label: "普通", value: "1" },
                { label: "当座", value: "2" },
                { label: "預金", value: "3" },
              ]}
              defaultValue={registerState.bankType.value}
              onChange={(e) =>
                registerDispatch({
                  type: RegisterActionType.ACTION_INPUT_BANK_TYPE,
                  payload: { inputValue: e.target.value },
                })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>口座番号(お支払用)</RowTitle>
          <RowContent>
            <CommonTextField
              value={registerState.bankNumber.value}
              onChange={(e) =>
                registerDispatch({
                  type: RegisterActionType.ACTION_INPUT_BANK_NUMBER,
                  payload: { inputNumberValue: e.target.value },
                })
              }
            />
          </RowContent>
        </Row>
        <CommonButton onClick={valudationAndNext} disabled={registerCheckSum!==13}>確認</CommonButton>
        {/* <CommonButton onClick={()=>console.log(registerCheckSum)}>テスト。レジスターstate</CommonButton> */}
      </Container>
    );
  } else if (mode === 2) {
    return (
      <Container>
        <Row>
          <RowTitle>メールアドレス</RowTitle>
          <RowContent>{registerState.mailAddress.value}</RowContent>
        </Row>
        <Row>
          <RowTitle>マンション名</RowTitle>
          <RowContent>{registerState.manshonName.value}</RowContent>
        </Row>
        <Row>
          <RowTitle>マンション住所</RowTitle>
          <RowContent>{registerState.manshonAddress.value}</RowContent>
        </Row>

        <Row>
          <RowTitle>法人名</RowTitle>
          <RowContent>{registerState.companyName.value}</RowContent>
        </Row>
        <Row>
          <RowTitle>法人住所</RowTitle>
          <RowContent>{registerState.companyAddress.value}</RowContent>
        </Row>

        <Row>
          <RowTitle>代表者氏名</RowTitle>
          <RowContent>{registerState.companyPresidentName.value}</RowContent>
        </Row>
        <Row>
          <RowTitle>担当者氏名</RowTitle>
          <RowContent>{registerState.companyManagerName.value}</RowContent>
        </Row>

        <Row>
          <RowTitle>携帯電話番号</RowTitle>
          <RowContent>{registerState.phoneNumber.value}</RowContent>
        </Row>
        <Row></Row>

        <Row>
          <RowTitle>銀行名(お支払い口座)</RowTitle>
          <RowContent>{registerState.bankName.value}</RowContent>
        </Row>
        <Row>
          <RowTitle>支店名(お支払い口座)</RowTitle>
          <RowContent>{registerState.bankBranchName.value}</RowContent>
        </Row>
        <Row>
          <RowTitle>種類(お支払い口座)</RowTitle>
          <RowContent>
            {bankTypeCharacter(registerState.bankType.value!)}
          </RowContent>
        </Row>
        <Row>
          <RowTitle>口座番号(お支払い口座)</RowTitle>
          <RowContent>{registerState.bankNumber.value}</RowContent>
        </Row>
        <CommonButton onClick={() => setMode(1)}>戻る</CommonButton>
        <CommonButton onClick={register}>登録</CommonButton>
      </Container>
    );
  }

  return <>このページは不正です</>;
};

const RegisterWrapper = styled.div`
  /* width:100%;
height:100%;
display:flex ;
flex-direction:column ;
align-items:start; */
`;

const CommonButton = styled(_CommonButton)`
  margin-bottom: 10px;
`;
