import styled from "styled-components";
import { CommonButton } from "../../utils/Button";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as color from "../../utils/color";
import { CommonSelect } from "../../utils/Select";
import { ReqAndRes } from "../../api";
import {
  DAYWIDTH,
  DISPLAYUNIT,
  ScheduleComponentType as ScheduleComponentType,
  usageCarInfoType,
  TIMELABELS,
  selectTimeData,
} from "./calendarConfig";
import "dayjs/locale/ja";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.locale("ja");
dayjs.extend(utc);
dayjs.extend(timezone);

// 開いてるか、開いてないかという部分を切り出した。
const checkIsOpenAndGetScheduleComponent = ({
  startOfTimeSpan,
  endOfTimeSpan,
  notAvailableSpan}:{
  startOfTimeSpan: dayjs.Dayjs;
  endOfTimeSpan: dayjs.Dayjs;
  notAvailableSpan: { start: string; end: string; type?: string };
}): number => {
  // 初期値
  let notAvailableSpanStart = dayjs(notAvailableSpan.start);
  let notAvailableSpanEnd = dayjs(notAvailableSpan.end);
  // 初期値
  let isOpen = 1;
  // 枠が埋まっているというは以下の3つの条件を満たすことである。
  // 枠に関しては(start,end]という半閉区間をもうけた。
  // console.log(`条件1：空いてない時間のスタートは枠の最後より前${condition1}`)
  let condition1 =
    endOfTimeSpan.isSameOrAfter(notAvailableSpanStart) &&
    endOfTimeSpan.isSameOrBefore(notAvailableSpanEnd);
  // console.log(`条件2：空いてない時間のエンドは枠の最初の後${condition2}`)
  let condition2 =
    startOfTimeSpan.isAfter(notAvailableSpanStart) &&
    startOfTimeSpan.isBefore(notAvailableSpanEnd);
  // 間にいる場合
  let condition3 =
    startOfTimeSpan.isSameOrBefore(notAvailableSpanStart) &&
    endOfTimeSpan.isSameOrAfter(notAvailableSpanEnd);

  // そもそも現在よりも前の時間枠は無視
  let condition4 = startOfTimeSpan.isBefore(dayjs());
  if (condition1 || condition2 || condition3 || condition4) {
    isOpen = 0;
  } else {
    // tmp_ok=1
  }
  // 例外処理
  if (endOfTimeSpan.isSame(notAvailableSpanStart)) {
    isOpen = 1;
  }
  console.log("終わり")
  return isOpen;
};

export const CarSchedule = ({
  onClick,
  isLate,
  notAvailableSpanArray,
}: {
  onClick?: (usageCarInfo: usageCarInfoType) => void;
  isLate?: Boolean;
  notAvailableSpanArray?: ReqAndRes["GET cars/schedule/"]["res"]["data"];
}) => {
  // 遅れているときは△、そうでないときは×
  const XcharacterOfSchedule = isLate ? "●" : "×";

  const [scheduleData, setScheduleData] = useState<any[][]>([]);
  // const [useTime, setUseTime] = useState(2);


  const [dayLabelArray, setDayLabelArray] = useState<string[]>([]);
  const [week, setWeek] = useState(0);
  const [loading,setLoading]=useState(true)

  // 関数として切り出す。
  // 開いている日、開いてない日

  const calculateCarendar = async ()=>{
    // if (!notAvailableSpanArray){
    //   console.log("ないのでリターン")
    //   return
    // }
    // if (notAvailableSpanArray.length === 0){
    //   console.log("ないのでリターン")
    //   return
    // }

    let notAvailableSpanArrayTmp = notAvailableSpanArray;

    // ここで何をしているか？
    // 遅延予約の場合は通常の場合とはそもそも異なる。なのでそれのためにデータを補正している。
    // 要は、一番予約開始が早いものからずっと埋まっていると表示させたい。
    // 条件は、延滞予約でありかつ、データが有る時。延滞の場合は予約せざるを得ない。
    if (isLate && !!notAvailableSpanArray) {
      let minData: { start: string; end: string };
      // 埋まっている配列の中で最も、
      minData = notAvailableSpanArray.reduce(
        (min, tmp) => {
          return dayjs(min.start).isBefore(tmp.start!) ? min : tmp;
        },
        // 初期データ
        {
          start: "2030-09-27 08:00:00.000000",
          end: "2030-09-27 12:00:00.000000",
        }
      );

      // 仮で入れているが問題ない。
      minData.end = "2200-09-27 12:00:00.000000";
      notAvailableSpanArrayTmp = [minData!];
    }

    // 日付を取得する。基準値
    let todayZero = dayjs().tz('Asia/Tokyo').startOf("day");
    console.log(`今日:${todayZero}`)
    // 箱
    let dayLabelArrayTmp = [];
    // ○✗をつける配列 日付x時間の個数分だけある。
    let DayArrayOfScheduleComponent: ScheduleComponentType[][] = [];

    // 日付でforを回す。
    for (let dayIterator = 0; dayIterator < 7; dayIterator++) {
      // 日付タイトル部分
      let startDayOfSchedule = todayZero.add(dayIterator + week * 7, "d");
      let TimeArrayOfScheduleComponent: ScheduleComponentType[] = [];
      // 時間で１２回ループさせる。
      for (let timeIterator = 0; timeIterator < 12; timeIterator++) {
        // 時間枠。デバッグした限りただしそう。
        // 時間枠の中のスタート
        let startOfTimeSpan = startDayOfSchedule.add(timeIterator * 2, "h");
        // 時間枠の中の終わり　利用時間に合わせて、枠の終わりを伸ばす。ロジック上　なぜ？！空いているか空いていないかを判定するため。
        let endOfTimeSpan = startDayOfSchedule.add(
          timeIterator * 2,
          "h"
        );
        // let time_span_end_plus = day_tmp.add(time * 2 + Number(useTime), "h");
        // デフォルトを空いていることにする。
        // let isOpen = 1;
        let isOpenList:any[] =[]
        // すべての利用不可期間に対して、開いてるかどうかを探索する。
        !!notAvailableSpanArrayTmp && notAvailableSpanArrayTmp!.forEach((notAvailableSpan) => {
          let isOpen = checkIsOpenAndGetScheduleComponent({
            startOfTimeSpan,
            endOfTimeSpan,
            notAvailableSpan})
          isOpenList.push({isOpen:isOpen,type:notAvailableSpan.type})
        
        });

        

        // console.log(isOpenList)
        let isOpen = isOpenList.every(value=>value.isOpen === 1)

        // // 利用不可期間が何もない場合はtrueとする
        if (!notAvailableSpanArrayTmp) {isOpen = true}

        TimeArrayOfScheduleComponent.push({
          start: startOfTimeSpan,
          endOfUse: endOfTimeSpan,
          isOpen: Number(isOpen),
        });
      }
      DayArrayOfScheduleComponent.push(TimeArrayOfScheduleComponent);

      let day_tmp2 = startDayOfSchedule.tz('Asia/Tokyo').format("MM/D\n(ddd)");
      dayLabelArrayTmp.push(day_tmp2);
    }
    setScheduleData(DayArrayOfScheduleComponent);
    setDayLabelArray(dayLabelArrayTmp);

  }

  // カレンダーにいれるデータを作っている。
  useEffect(() => {
    (async ()=>{
      await calculateCarendar()
      setLoading(false)
  })()
  }, [notAvailableSpanArray,week]);

  // useReducerでかきなおす？ローディングがあまり意味をなしていない。
  const changeWeek = (newWeek:number)=>{
    setLoading(true)
    setWeek(newWeek)
  }

  

  if (loading){
    return <>予定を取得しています</>
  }

  return (
    <>
      {/* <div>タイムスケジュール</div> */}
      <WeekButton>
        <ButtonWrapper>
          {week > 0 && (
            <CommonButton
              onClick={() => {
                changeWeek(week-1)
              }}
            >
              前の週
            </CommonButton>
          )}
        </ButtonWrapper>

        <ButtonWrapper>
          {/* 3週間先まで予約できるようになっている */}
          {week < 2 && (
            <CommonButton
            onClick={() => {
              changeWeek(week+1)
            }}
            >
              次の週
            </CommonButton>
          )}
        </ButtonWrapper>
      </WeekButton>
      <ScheduleWraper>
        {/* 日付のラベルを記載する部分 空白をいれて１＋７個分ある */}
        <DayWrapper>
          <Day>
            {/* 左上の空白部分 */}
            <SheduleLabel />
          </Day>
          {/* forEachで書き直してもいいかもしれない */}
          {dayLabelArray.map((label, index) => {
            return (
              <Day key={index + "daylabel"}>
                <SheduleLabel>{label}</SheduleLabel>
              </Day>
            );
          })}
        </DayWrapper>
        {/* 実際のデータの部分。 ７個分ある。*/}
        <DayWrapper>
          {/* １列目　時間のラベルの部分に対応している。 */}
          <Day>
            {TIMELABELS.map((label, index) => {
              return (
                <SheduleLabel key={index + "timelabel"}>{label}</SheduleLabel>
              );
            })}
          </Day>
          {/* ２列目以降で実際のデータが入っている。　*/}
          {scheduleData.map((day, index_day) => {
            let time_div = day.map((timeSpan, indexTime) => {
              return (
                <Time
                  key={indexTime + index_day + "time"}
                  isOpen={timeSpan.isOpen}
                >
                  <OpenOrClose isOpen={timeSpan.isOpen}>
                    {timeSpan.isOpen ? "○" : XcharacterOfSchedule}
                  </OpenOrClose>
                </Time>
              );
            });
            return <Day key={index_day + "day"}>{time_div}</Day>;
          })}
        </DayWrapper>
      </ScheduleWraper>
    </>
  );
};

const WeekButton = styled.div`
  display: flex;
  justify-content: space-between;
  /* Dayの大きさから計算 */
  width: ${DAYWIDTH * (DISPLAYUNIT + 1)}px;
`;

const ButtonWrapper = styled.div`
  /* justify-content:left ; */
  /* border:solid 1px ; */
`;

const DayWrapper = styled.div`
  display: flex;
`;

const Day = styled.div`
  display: flex;
  flex-direction: column;
  width: ${DAYWIDTH}px;
`;

const SheduleLabel = styled.div`
  /* display:flex; */
  height: 30px;
  border: solid 1px ${color.Gray2};
  /* background-color: ${color.Gray2}; */
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-weight: 500;
`;

const Time = styled.div<{ isOpen?: number | null }>`
  /* display:flex; */
  height: 30px;
  border: solid 1px ${color.Gray1};
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isOpen: isOpen }) =>
    !isOpen ? `background-color: ${color.Gray4}` : `;`};

  ${({ isOpen: isOpen }) =>
    !isOpen
      ? `border: solid 1px ${color.Gray4};`
      : `border: solid 1px ${color.Gray1};`};
`;

const OpenOrClose = styled.span<{ isOpen: number | null }>`
  ${({ isOpen }) => !isOpen && `color:${color.Red}`};
  font-size: 14px;
`;

const ScheduleWraper = styled.div`
  /* margin: 4px; */
  /* border: 2px solid ${color.Gray2}; */
  /* border-radius: 5px; */
`;
