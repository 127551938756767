export const BadNewsType:{[key:string]:string} = {
    "accident":"事故",
    "penalty":"違反",
    "resolved":"解決済みの事故及び違反",
  }

  export const planName:{[key:string]:string} = {
    "none":"プラン未登録",
    "platinum":"プラチナム会員",
    "gold":"ゴールド会員",
    "basic":"ベーシック会員"
  }

  // export const URLForRegidenceUser = "http://localhost:3001/register"
  export const URLForRegidenceUser ="https://front.forest-sapporo-car-sharing.com/register"