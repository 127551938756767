import styled from "styled-components"
import { Link } from "react-router-dom"
import { useEffect,useState } from "react"
import { api,ReqAndRes } from "../../api"
import dayjs from "dayjs";
import { planName } from "../../utils/typeAndConfig";
import * as color from "../../utils/color";

export const UserList = ()=>{

    const [data,setData] = useState<ReqAndRes["GET users/owner/residence_users/"]["res"]["data"]["results"]>([])

    useEffect(
        ()=>{
            (async ()=>{
                await api("GET users/owner/residence_users/",null).then
                ((res)=>{
                    console.log(res)
                    setData(res.data.results)
                }).catch(
                    (err)=>{console.log(err)}
                )
            })()
        },[]
    )
    
    return <UserListWrapper>
        <h1>会員ユーザ一覧</h1>
        <UserListItemHeaderWrapper>
            <UserName>ユーザ名</UserName>
            {/* <ManshonName>マンション名</ManshonName> */}
            <UserStatus>プラン</UserStatus>
            <PointUserHas>保有ポイント</PointUserHas>
            <RegisterationDate>登録日</RegisterationDate>
        </UserListItemHeaderWrapper>
        {data!.map(
            (v,index)=>{
                return <Link to={String(v.id)} key={v.id}><UserListItemWrapper>
                <UserName>{v.fullName}</UserName>
                {/* <ManshonName>{v.r}</ManshonName> */}
                <UserStatus>{planName[v.plan!]}</UserStatus>
                <PointUserHas>{v.lastPoint}</PointUserHas>
                <RegisterationDate>{dayjs(v.dateJoined).format("YYYY-MM-DD")}</RegisterationDate>
            </UserListItemWrapper>
            </Link>
            }
        )}
        
        </UserListWrapper>
}

const UserListWrapper = styled.div`
margin:10px;
`


const UserListItemWrapper =  styled.div`
display: flex;
padding:10px;
border: solid 1px ${color.Gray4};
/* width:100px; */
`

const UserListItemHeaderWrapper =  styled(UserListItemWrapper)`
font-weight:bold;
border: solid 1px ${color.Gray4};
background-color:${color.Black};
color:${color.White};

/* font-size:12px; */
`

const UserName =  styled.div`
text-align:left ;
width:200px;
`

// const ManshonName =  styled.div`
// text-align:left ;
// width:200px;
// `

const UserStatus =  styled.div`
width:150px;
`

const PointUserHas =  styled.div`
width:150px;
`

const RegisterationDate =  styled.div`
width:200px;
`

