import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CommonButton } from "../utils/Button";

const ImageComponent: React.FC = () => {
  const { imgURL } = useParams();
  const [img, setImg] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    console.log("動いた");
    setImg("https://storage.forest-sapporo-car-sharing.com/" + imgURL);
  }, [imgURL]);

  // imgURLがundefinedでないことを確認
  if (!imgURL) {
    return <div>No Image URL provided</div>;
  }
  console.log(img);

  // const decodedURL = decodeURIComponent(imgURL);

  return (
    <Wrapper>
      <CommonButton margin="5px;" onClick={()=>handleGoBack()}>戻る</CommonButton>
      <div>
        <img src={img} />
      </div>
      <CommonButton margin="5px;" onClick={()=>handleGoBack()}>戻る</CommonButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
display:flex;
flex-direction:column ;
`
export default ImageComponent;
