type inputStringType = {
  value?: string;
  validation?: string[];
};
type inputNumberType = {
  value?: number;
  validation?: string[];
};

export type RegisterStateType = {
  sei: inputStringType;
  mei: inputStringType;
  seiKana: inputStringType;
  meiKana: inputStringType;
  prefecture: inputStringType;
  sikutyoson: inputStringType;
  address: inputStringType;
  manshonName: inputStringType;
  manshonAddress: inputStringType;
  companyName: inputStringType;
  companyAddress: inputStringType;
  companyPresidentName: inputStringType;
  companyManagerName: inputStringType;
  bankName: inputStringType;
  bankBranchName: inputStringType;
  bankType: inputStringType;
  bankNumber: inputNumberType;
  phoneNumber: inputStringType;
  mailAddress:inputStringType;
  password:inputStringType;
};

const initailInput = {
  value: "",
  validation: [],
};

export const inistialRegisterState: RegisterStateType = {
  sei: initailInput,
  mei: initailInput,
  seiKana: initailInput,
  meiKana: initailInput,
  prefecture: initailInput,
  sikutyoson: initailInput,
  address: initailInput,
  manshonName: initailInput,
  manshonAddress: initailInput,
  companyName: initailInput,
  companyAddress: initailInput,
  companyPresidentName: initailInput,
  companyManagerName: initailInput,
  bankName: initailInput,
  bankBranchName: initailInput,
  bankType: initailInput,
  bankNumber: { value: undefined, validation: [] },
  phoneNumber: initailInput,
  mailAddress:initailInput,
  password:initailInput
};

export enum RegisterActionType {
  // ACTION_INPUT_SEI = "ACTION_INPUT_SEI",
  // ACTION_INPUT_MEI = "ACTION_INPUT_MEI",
  // ACTION_INPUT_SEI_KANA = "ACTION_INPUT_SEI_KANA",
  // ACTION_INPUT_MEI_KANA = "ACTION_INPUT_MEI_KANA",
  // ACTION_INPUT_PREFECTURE = "ACTION_INPUT_PREFECTURE",
  // ACTION_INPUT_SIKUTYOSON = "ACTION_INPUT_SIKUTYOSON",
  // ACTION_INPUT_ADDRESS = "ACTION_INPUT_ADDRESS",
  ACTION_INPUT_MANSHON_NAME= "ACTION_INPUT_MANSHON_NAME",
  ACTION_INPUT_MANSHON_ADDRESS= "ACTION_INPUT_MANSHON_ADDRESS",
  ACTION_INPUT_COMPANY_NAME= "ACTION_INPUT_COMPANY_NAME",
  ACTION_INPUT_COMPANY_ADDRESS= "ACTION_INPUT_COMPANY_ADDRESS",
  ACTION_INPUT_COMPANY_PRESIDENT_NAME= "ACTION_INPUT_COMPANY_PRESIDENT_NAME",
  ACTION_INPUT_COMPANY_MANAGER_NAME= "ACTION_INPUT_COMPANY_MANAGER_NAME",
  ACTION_INPUT_PHONE_NUMBER = "ACTION_INPUT_PHONE_NUMBER",
  ACTION_INPUT_BANK_NAME = "ACTION_INPUT_BANK_NAME",
  ACTION_INPUT_BANK_BRANCH_NAME = "ACTION_INPUT_BANK_BRANCH_NAME",
  ACTION_INPUT_BANK_TYPE = "ACTION_INPUT_BANK_TYPE",
  ACTION_INPUT_BANK_NUMBER = "ACTION_INPUT_BANK_NUMBER",
  ACTION_INPUT_MAIL_ADDRESS = "ACTION_INPUT_MAIL_ADDRESS",
  ACTION_INPUT_PASSWORD = "ACTION_INPUT_PASSWORD",
}

export type RegisterPayloadType = {
  inputValue?: string;
  inputNumberValue?: number;
};


export type RegisterAction = {
  type: RegisterActionType;
  payload: RegisterPayloadType;
};

export const RegisterReducer: React.Reducer<
RegisterStateType,
RegisterAction
>  = (state,action) => {
  switch (action.type) {

    // case "ACTION_INPUT_SEI": {
    //   return {
    //     ...state,
    //     sei: { value: action.payload.inputValue!, validation: [] },
    //   };
    // }
    // case "ACTION_INPUT_MEI": {
    //   return {
    //     ...state,
    //     mei: { value: action.payload.inputValue!, validation: [] },
    //   };
    // }
    // case "ACTION_INPUT_SEI_KANA": {
    //   return {
    //     ...state,
    //     seiKana: { value: action.payload.inputValue!, validation: [] },
    //   };
    // }
    // case "ACTION_INPUT_MEI_KANA": {
    //   return {
    //     ...state,
    //     meiKana: { value: action.payload.inputValue!, validation: [] },
    //   };
    // }

    case "ACTION_INPUT_MAIL_ADDRESS": {
      return {
        ...state,
        mailAddress: { value: action.payload.inputValue!, validation: [] },
      };
    }


    case "ACTION_INPUT_PASSWORD": {
      return {
        ...state,
        password: { value: action.payload.inputValue!, validation: [] },
      };
    }

    // case "ACTION_INPUT_PREFECTURE": {
    //   return {
    //     ...state,
    //     prefecture: { value: action.payload.inputValue!, validation: [] },
    //   };
    // }

    // case "ACTION_INPUT_SIKUTYOSON": {
    //   return {
    //     ...state,
    //     sikutyoson: { value: action.payload.inputValue!, validation: [] },
    //   };
    // }

    // case "ACTION_INPUT_ADDRESS": {
    //   return {
    //     ...state,
    //     address: { value: action.payload.inputValue!, validation: [] },
    //   };
    // }
    case "ACTION_INPUT_MANSHON_NAME": {
        return {
          ...state,
          manshonName: { value: action.payload.inputValue!, validation: [] },
        };
      }
      case "ACTION_INPUT_MANSHON_ADDRESS": {
        return {
          ...state,
          manshonAddress: { value: action.payload.inputValue!, validation: [] },
        };
      }
      case "ACTION_INPUT_COMPANY_ADDRESS": {
        return {
          ...state,
          companyAddress: { value: action.payload.inputValue!, validation: [] },
        };
      }
      case "ACTION_INPUT_COMPANY_NAME": {
        return {
          ...state,
          companyName: { value: action.payload.inputValue!, validation: [] },
        };
      }
      case "ACTION_INPUT_COMPANY_PRESIDENT_NAME": {
        return {
          ...state,
          companyPresidentName: { value: action.payload.inputValue!, validation: [] },
        };
      }

      case "ACTION_INPUT_COMPANY_MANAGER_NAME": {
        return {
          ...state,
          companyManagerName: { value: action.payload.inputValue!, validation: [] },
        };
      }

    case "ACTION_INPUT_PHONE_NUMBER": {
      return {
        ...state,
        phoneNumber: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case "ACTION_INPUT_BANK_NAME": {
      return {
        ...state,
        bankName: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case "ACTION_INPUT_BANK_BRANCH_NAME": {
      return {
        ...state,
        bankBranchName: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case "ACTION_INPUT_BANK_TYPE": {
      return {
        ...state,
        bankType: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case "ACTION_INPUT_BANK_NUMBER": {
      return {
        ...state,
        bankNumber: { value: action.payload.inputNumberValue!, validation: [] },
      };
    }
    default:
      return inistialRegisterState;
  }
};
