import styled from "styled-components";
// import * as color from "../../utils/color";
import { CommonButton } from "../../utils/Button";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { api } from "../../api";
import { ImageURL } from "../../setting";

export const CarList = () => {
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    (async () => {
      api("GET cars/", null)
        .then((res) => {
          setData(res.data.results);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  }, []);

  return (
    <CardListWrapper>
      <h1>車両一覧</h1>
      <ButtonWrapper>
      <Link to="register">
        <CommonButton width="200px" margin={"5px"}>
          新しい車両を登録する
        </CommonButton>
      </Link>
      </ButtonWrapper>
      {data.map((car, index) => {
        return <CarCard car={car} key={index} />;
      })}
    </CardListWrapper>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CardListWrapper = styled.div`
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
`;

const CarCard = ({ car }: { car: any }) => {
  return (
    <Link to={"/cars/" + car.id}>
      <CarCardWrapper>
        <div>
          {/* <CarCardImage imgPath={car.thumunailUrl}></CarCardImage> */}
          <CarCardImage src={ImageURL + car.thumbnailImage} alt="" />
        </div>
        <CarCardInfo>
          <div>
            登録ステータス：{car.isDraft ? "一時保存" : "登録済み"}　/　貸出ステータス：
            {car.isAvailable ? "貸出可能" : "貸出不可"}
          </div>
          <div>駐車場：{car.parkingAddress}</div>
          <div>車両名称：{car.name}</div>
          <div>予約ステータス：{car.rental}</div>
        </CarCardInfo>
      </CarCardWrapper>
    </Link>
  );
};

const CarCardWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  /* padding:5px ; */
  border: solid 1px;
  border-radius: 5px;
`;
// 画像を自動で真ん中から切り取るには、画像ではなく、divで指定する。
// const CarCardImage = styled.div<{imgPath:string}>`
//     ${({imgPath})=>imgPath?`background-image: url(${imgPath});;`:`;`}
//     width: 400px;
//     height: 400px;
//     background-position: center center;
//     background-repeat: no-repeat;
//   `

const CarCardImage = styled.img`
  width: 300px;
  height: 200px;
  object-fit: cover;
  object-position: 100% 30;
  border-radius: 5px;
  /* aspect-ratio: 16 / 9; */
  /* height:100%; */
  /* width:350px; */
  /* object-fit: none; */
  /* object-fit: scale-down; */
  /* border:solid 1px; */
`;
const CarCardInfo = styled.div``;
