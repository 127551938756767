import styled from "styled-components";
import * as color from "../utils/color";
import { useState } from "react";
import { CommonButton as _CommonButton } from "../utils/Button";
import { Link } from "react-router-dom";
import { CommonTextField } from "../utils/TextField";
import { Row, RowTitle, RowContent } from "../utils/Common";

import LogoIcon from "../sozai/logo.jpg";
import { api } from "../api";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";

export function Login() {
  const [mailAddress, setMailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const disptch = useDispatch();

  // const [loading, setLoading] = useState(false);
  // ログイン関数
  const login = () => {
    setErrorMessages("")
    ;(async () => {
      await api("POST auth/owner-login/", { email: mailAddress, password: password })
        .then((res) => {
          // アクセストークンの保存
          localStorage.setItem("accessToken", res.data.accessToken);
          // リフレッシュトークンの保存
          localStorage.setItem("refreshToken", res.data.refreshToken);
          // トークン取得日の保存。
          localStorage.setItem("tokenDate", dayjs().format());
          // 問題ないので、ログイン状態に変える。
          disptch({ type: "Login.SetLogin" });
        })
        .catch((e) => {
          console.log(e);
          setErrorMessages("メールアドレスかパスワードが間違っています。")
        });
    })();
  };

  // const [loading, setLoading] = useState(false);
  // ログイン関数

  // ローカルストレージに保存する。
  //
  // const login = () => {
  //   // ログイン処理
  //   (async () => {})();
  // };

  return (
    <Center>
      <h1>カーシェアリングシステム for オーナー</h1>
      <LoginBox>
        <Row>
          <RowTitle>メールアドレス</RowTitle>
          <RowContent>
            <MailFeild
              id="mail-address"
              value={mailAddress}
              label="メールアドレス"
              variant="outlined"
              onChange={(e) => {
                setMailAddress(e.target.value);
              }}
            />
          </RowContent>
        </Row>

        <Row>
          <RowTitle>パスワード</RowTitle>
          <RowContent>
            <PasswordFeild
              id="password"
              value={password}
              label="Password"
              type="password"
              variant="outlined"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </RowContent>
        </Row>
        {ErrorMessage && <ErrorMessage>{errorMessages}</ErrorMessage>}
        <CommonButton onClick={login}>ログイン</CommonButton>
        <Link to="/register/">
          <CommonButton>会員登録</CommonButton>
        </Link>
      </LoginBox>
    </Center>
  );
}

const ErrorMessage = styled.div`
color: ${color.Red};
`

const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoginBox = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  padding: 0;
  width: 600px;
`;

const MailFeild = styled(CommonTextField)`
  margin-bottom: 4px;
`;

const PasswordFeild = styled(CommonTextField)``;

const CommonButton = styled(_CommonButton)`
  margin-bottom: 20px;
`;
