import styled from "styled-components";
import { CommonButton } from "../../utils/Button";
import { Link } from "react-router-dom";
import { ImageURL } from "../../setting";
import dayjs from "dayjs";
import { ReqAndRes } from "../../api";

export const DamageHistory = ({
  damageData,
}: {
  damageData: ReqAndRes["RETRIEVE cars/"]["res"]["data"]["damageReports"];
}) => {
  // 車IDを用いて叩く。
  return (
    <DamageHistoryWrapper>
      <h3>傷報告履歴(最新５件)</h3>

      <div>
        {damageData.map((v, index) => {
          return (
            <UseHistoryItemWrapper key={index}>
              <div>
                <h4>
                  {dayjs(v.createdAt).format("YYYY-MM-DD HH:mm") +
                    " " +
                    v.user.fullName}
                  <CommonButton width="100px;">
                    {v.isChecked ? <>確認済</> : <>確認済みに変更</>}
                  </CommonButton>
                </h4>
                {v.textContent}
              </div>
              <div>
                <ImageItem src={ImageURL + v.damegeImage}></ImageItem>
              </div>
            </UseHistoryItemWrapper>
          );
        })}
      </div>
    </DamageHistoryWrapper>
  );
};

const DamageHistoryWrapper = styled.div`
  margin: 5px;
`;

const UseHistoryItemWrapper = styled.div`
  margin: 5px;
`;
const ImageWrapper = styled.div`
  display: flex;
`;
const ImageItem = styled.img`
  width: 150px;
  /* height:200px; */
  margin-left: 10px;
`;
