import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";
import { ImageURL } from "../../setting";
import { ReqAndRes } from "../../api";



export const ImageCarousel = ({car}:{car?:ReqAndRes["RETRIEVE cars/"]["res"]["data"]}) => {
  return (
    <ImageCaruouselWrapper>
      {!!car &&
      <Carousel centerSlidePercentage={1}>
        <CarPartImageWrapper>
          <img src={ImageURL+car.thumbnailImage!} alt="Thubmnail"/>
          <p className="legend">サムネイル</p>
        </CarPartImageWrapper>
        <CarPartImageWrapper>
        <img src={ImageURL+car.frontImage!} alt=""/>
          <p className="legend">正面</p>
        </CarPartImageWrapper>
        <CarPartImageWrapper>
          <img src={ImageURL+car.rightImage!} alt=""/>
          <p className="legend">右側</p>
        </CarPartImageWrapper>
        <CarPartImageWrapper>
          <img src={ImageURL+car.leftImage} alt=""/>
          <p className="legend">左側</p>
        </CarPartImageWrapper>
        <CarPartImageWrapper>
          <img src={ImageURL+car.backImage} alt=""/>
          <p className="legend">背面</p>
        </CarPartImageWrapper>
        <CarPartImageWrapper>
          <img src={ImageURL+car.frontLeftImage} alt=""/>
          <p className="legend">前左</p>
        </CarPartImageWrapper>
        <CarPartImageWrapper>
          <img src={ImageURL+car.frontRightImage} alt=""/>
          <p className="legend">前右</p>
        </CarPartImageWrapper>
        <CarPartImageWrapper>
          <img src={ImageURL+car.backLeftImage} alt=""/>
          <p className="legend">後左</p>
        </CarPartImageWrapper>
        <CarPartImageWrapper>
          <img src={ImageURL+car.backRightImage} alt=""/>
          <p className="legend">後右</p>
        </CarPartImageWrapper>

        
      </Carousel>
}
    </ImageCaruouselWrapper>
  );
};

const ImageCaruouselWrapper=styled.div`
width:100%;
/* height:300px; */
`


const CarPartImageWrapper = styled.div`
aspect-ratio: 16 / 9;
width:100%;
/* height:300px; */
`

