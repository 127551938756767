import styled from "styled-components";
import { CommonButton } from "../../utils/Button";
import {Link} from "react-router-dom";
import { ReqAndRes } from "../../api";
import dayjs from "dayjs";

export const UseHistory = ({
  reservations
}: {
  reservations: ReqAndRes["RETRIEVE cars/"]["res"]["data"]["reservations"];
}) => {
  return <UseHistoryWrapper>
      <h3>利用履歴(最新５件)</h3>
      <div>
        {reservations.map((v, index) => {
          return (
            <UseHistoryItemWrapper key={index}>
              <Link to={"/usages/"+v.id}><CommonButton width="300px;">{dayjs(v.startReservationDateTime).format("YYYY-MM-DD HH:mm")}- {dayjs(v.endReservationDateTime).format("YYYY-MM-DD HH:mm")} {v.user!.fullName}</CommonButton></Link>
            </UseHistoryItemWrapper>
          );
        })}
      </div>
    </UseHistoryWrapper>
};


const UseHistoryWrapper = styled.div`
margin:5px;
`

const UseHistoryItemWrapper = styled.div`
margin:5px;
`