

// ここから色の定義
export const Navy = '#001f3f'
export const Blue = '#0074D9'
export const Aqua = '#7FDBFF'
export const Teal = '#39CCCC'
export const Olive = '#3D9970'
export const Green = '#2ECC40'
export const Lime = '#01FF70'
export const Yellow = '#FFDC00'
export const Orange = '#FF851B'
export const DeepRed = '#FF4136'
export const Red = '#CC6666'
export const Maroon = '#85144b'
export const Fuchsia = '#F012BE'
export const Purple = '#B10DC9'
export const Black = '#111111'
export const Gray1 = '#f5f5f5'
export const Gray2 = '#dcdcdc'
export const Gray3 = '#d3d3d3'
export const Gray4 = '#c0c0c0'
export const Gray5 = '#a9a9a9'
export const Gray6 = '#808080'
export const Gray7 = '696969'
export const Silver = '#D0D0D0'
export const LightSilver = '#F8F8F8'
export const White = '#FFFFFE'


// 実用性を考慮したセッティング要
export const Main = '#0074D9'
export const CanselColor = Red
export const AgreeColor = Green
