import styled from "styled-components";
import * as color from "./color";

export const CommonButton = styled.button<{width?:string,margin?:string,padding?:string}>`
/* ここらへんは使うところで継承して使えば良い */
  /* margin-top: 16px; */
  /* margin-right: 16px; */
  font-size:12px;
  color:${color.Black};
  background-color: ${color.Gray2};
  /* padding: 8px; */
  ${({padding})=> padding ? `padding:${padding};`:`padding: 8px;`}
  border:0px;
  border-radius: 4px;
  /* font-size: 12px; */
  flex-grow: 0;
  ${({width})=> width ? `width:${width};`:`width:100%;`}
  ${({margin})=> margin ? `margin:${margin};`:`;`}
  :disabled{
    background-color: ${color.Gray6};
  }
`;

export const CancelButton = styled(CommonButton)`
background-color:${color.CanselColor} ;
`

export const AgreeButton = styled(CommonButton)`
background-color:${color.AgreeColor} ;
`
