import { dayjsDatetime } from "../utils/dayjsFormatter";
import { CommonButton } from "../utils/Button";
import { BadNewsType } from "../utils/typeAndConfig";
import { api, ReqAndRes } from "../api";
export const BadNews = ({
  badnews,
  reloadAfter,
}: {
  badnews:
    | ReqAndRes["GET usages/bad-news/for-owner/"]["res"]["data"]["results"]
    | ReqAndRes["RETRIEVE usages/reservations-for-owner/"]["res"]["data"]["badNewsReports"];
  reloadAfter?: () => Promise<void>;
}) => {
  const checkBadNews = (id: number) => {
    (async () => {
      await api("PATCH usages/bad-news/for-owner/", {
        id: id,
        isOwnerCheck: true,
      })
        .then((res) => {
          console.log("成功");
          reloadAfter!();
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  };
  return (
    <>
      {badnews.map((v, index) => {
        return (
          <div key={index}>
            <h4>
            {v.isOwnerCheck ? "【確認済み】":"【未確認】"}
              {v.type &&
                v.accidentAt &&
                dayjsDatetime(v.accidentAt!) + " / " + BadNewsType[v.type!]}
            </h4>
            <div>{!!v.placeDetail && "引取場所：" + v.placeDetail}</div>
            <div>{!!v.managerName && "担当者名：" + v.managerName}</div>
            <div>
              {!!v.managerPhoneNumber &&
                "担当者電話番号" + v.managerPhoneNumber}
            </div>
            <div>{!!v.contentDetail && "詳細：" + v.contentDetail}</div>
            {v.isOwnerCheck ? (
                ""
              ) : (
                <CommonButton
                  width="100px;"
                  onClick={() => checkBadNews(v.id!)}
                >
                  確認済みにする
                </CommonButton>
              )}
          </div>
        );
      })}
    </>
  );
};
