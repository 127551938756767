import { useReducer, useState } from "react";
import styled from "styled-components";
import { CommonButton } from "../../utils/Button";
import {
  Container,
  RowContent,
  RowTitle,
  Row,
  Title,
  Content,
} from "../../utils/Common";
import { CommonSelect } from "../../utils/Select";
import { CommonTextField } from "../../utils/TextField";
import {
  RegisterReducer,
  initialCarState,
  CarRegisterActionType,
} from "../../reducer/registerCarReducer";
import { CommonFile } from "../../utils/CommonFile";
import { api } from "../../api";
import { Navigate } from "react-router-dom";
import { GradeSelect } from "../../setting";

export const CarRegister = () => {
  // 編集は、これを流用できる形まで洗礼してから行う。

  const [registerState, carRegisterDispatch] = useReducer(
    RegisterReducer,
    initialCarState
  );
  const [finish,setFinish]=useState(false)
  let checkSum = 0;
  (Object.keys(registerState) as (keyof (typeof initialCarState))[]).forEach(
    (v) => {
      if(!!registerState[v]!.value) {checkSum=checkSum+1}
    }
  )

  const data = {
    name: registerState.name?.value,
    grade: registerState.grade?.value,
    parkingAddress: registerState.parking?.value,
    specipication: registerState.specification?.value,
    size: registerState.size?.value,
    carNo: registerState.carNumber?.value,
    // 車検証
    carInspectionCertificate: registerState.carInspectionCertificate?.value,
    needPoint: registerState.needpoint?.value,
    registerDateOfCarInspection:
      registerState.registrationDateOfVehicleInspection?.value,
    numberPlate: registerState.numberPlate?.value,
    ownerTel: registerState.ownerPhoneNumber?.value,
    insuranceLicenseNo: registerState.insuranceLicenseNumber?.value,
    insuranceCompanyTel: registerState.insuranceCompanyTel?.value,
    insuranceCompanyContactName:registerState?.insuranceCompanyContactName?.value,
    insuranceCompanyName:registerState?.insuranceCompanyName?.value,
    keyBoxPlace: registerState.keyvoxPlace?.value,
    keyBoxNo: registerState.keyvoxNumber?.value,
    frontImage: registerState.bodyPicutreFront?.value,
    leftImage: registerState.bodyPicutreLeft?.value,
    rightImage: registerState.bodyPicutreRight?.value,
    backImage: registerState.bodyPicutreBack?.value,
    frontLeftImage: registerState.bodyPicutreFrontLeft?.value,
    frontRightImage: registerState.bodyPicutreFrontRight?.value,
    backLeftImage: registerState.bodyPicutreBackLeft?.value,
    backRightImage: registerState.bodyPicutreBackRight?.value,
    thumbnailImage: registerState.thumbnail?.value,
  };

  const tempRegister = () => {
    (async () => {
      const postData = {
        ...data,
        isDraft: true,
      };
      api("POST cars/", postData)
        .then((res) => {
          console.log(res);
          setFinish(true)
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  };
  const register = () => {
    // バリデーションの処理を入れること。
    (async () => {
      const postData = {
        ...data,
        isDraft: false,
      };
      api("POST cars/", postData)
        .then((res) => {
          console.log(res);
          // フラグ
          setFinish(true)
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  };

  if (!!finish){
    console.log("きいてる")
    return <Navigate to="/cars" replace/>
  }

  return (
    <Container>
      <Title>車両の登録</Title>
      {/* <button
        onClick={() => {
          console.log(registerState);
        }}
      >
        神ボタン
      </button>
      <button
        onClick={() => {
          console.log(checkSum);
        }}
      >
        チェックサム
      </button> */}
      <TopWrapper>
        <Left>
          <Content>
            <Row>
              <RowTitle>車両名</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_NAME,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.name?.value}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>グレード</RowTitle>
              <RowContent>
                <CommonSelect
                  selectProps={GradeSelect}
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_GRADE,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.grade?.value}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>駐車場</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_PARKING,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.parking?.value}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>仕様</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_SPECIFICATION,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.specification?.value}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>寸法</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_SIZE,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.size?.value}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>車体番号</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_CAR_NUMBER,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.carNumber?.value}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>車検証の写真</RowTitle>
              <RowContent>
                <CommonFile
                  setS3Path={(v) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_CAR_INSPECTION_CERTIFICATE,
                      payload: { inputValue: v },
                    });
                  }}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>必要ポイント</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_NEED_POINT,
                      payload: { inputNumberValue: e.target.value },
                    });
                  }}
                  value={registerState.needpoint?.value}
                  type="number"
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>車検登録日</RowTitle>
              <RowContent>
                <CommonTextField
                  type="date"
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_REGISTRATION_DATE_OF_VEICLE_INSPECTION,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={
                    registerState.registrationDateOfVehicleInspection?.value
                  }
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>ナンバープレート</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_NUMBER_PLATE,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.numberPlate?.value}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>保険会社名</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_INSURANCE_COMPANY_NAME,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.insuranceCompanyName?.value}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>保険会社契約者名</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_INSURANCE_LICENSE_NUMBER,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.insuranceLicenseNumber?.value}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>保険証保険番号</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_INSURANCE_LICENSE_NUMBER,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.insuranceLicenseNumber?.value}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>保険会社電話番号</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_INSURANCE_COMPANY_TEL,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.insuranceCompanyTel?.value}
                />
              </RowContent>
            </Row>
          </Content>
        </Left>
        {/* 画面右側。 */}
        <Right>
          <Content>
            <Row>
              <RowTitle>オーナーの電話番号</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_OWNER_PHONE_NUMBER,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.ownerPhoneNumber?.value}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>keyvoxの番号</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_KEYVOX_NUMBER,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.keyvoxNumber?.value}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>キーボックスの場所</RowTitle>
              <RowContent>
                <CommonTextField
                  onChange={(e) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_KEYVOX_PLACE,
                      payload: { inputValue: e.target.value },
                    });
                  }}
                  value={registerState.keyvoxPlace?.value}
                />
              </RowContent>
            </Row>
            <Row>
              <RowTitle>サムネイル写真</RowTitle>
              <RowContent>
                <CommonFile
                  setS3Path={(v) => {
                    carRegisterDispatch({
                      type: CarRegisterActionType.ACTION_INPUT_THUMNAIL,
                      payload: { inputValue: v },
                    });
                  }}
                />
              </RowContent>
            </Row>
          </Content>
        </Right>
      </TopWrapper>
      {/* 画面上部修了 */}
      {/* 画面下部画像のところ */}
      {/* ちょっと命名が気持ち悪いのでリファクタしよう。 */}
      <Bottom>
        <Content>
          <Bottom>
            <ImageBox>
              <div>前面</div>
              {/* <div>サンプル画像１</div> */}
              <CommonFile
                setS3Path={(v) => {
                  carRegisterDispatch({
                    type: CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_FRONT,
                    payload: { inputValue: v },
                  });
                }}
              />
            </ImageBox>
            <ImageBox>
              <div>左面</div>
              <CommonFile
                setS3Path={(v) => {
                  carRegisterDispatch({
                    type: CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_LEFT,
                    payload: { inputValue: v },
                  });
                }}
              />
            </ImageBox>
            <ImageBox>
              <div>右面</div>
              <CommonFile
                setS3Path={(v) => {
                  carRegisterDispatch({
                    type: CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_RIGHT,
                    payload: { inputValue: v },
                  });
                }}
              />
            </ImageBox>
            <ImageBox>
              <div>後面</div>
              <CommonFile
                setS3Path={(v) => {
                  carRegisterDispatch({
                    type: CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_BACK,
                    payload: { inputValue: v },
                  });
                }}
              />
            </ImageBox>
            <ImageBox>
              <div>前左</div>
              <CommonFile
                setS3Path={(v) => {
                  carRegisterDispatch({
                    type: CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_FRONT_LEFT,
                    payload: { inputValue: v },
                  });
                }}
              />
            </ImageBox>
            <ImageBox>
              <div>前右</div>
              <CommonFile
                setS3Path={(v) => {
                  carRegisterDispatch({
                    type: CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_FRONT_RIGHT,
                    payload: { inputValue: v },
                  });
                }}
              />
            </ImageBox>
            <ImageBox>
              <div>後左</div>
              <CommonFile
                setS3Path={(v) => {
                  carRegisterDispatch({
                    type: CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_BACK_LEFT,
                    payload: { inputValue: v },
                  });
                }}
              />
            </ImageBox>
            <ImageBox>
              <div>後右</div>
              <CommonFile
                setS3Path={(v) => {
                  carRegisterDispatch({
                    type: CarRegisterActionType.ACTION_INPUT_BODY_PICTURE_BACK_RIGHT,
                    payload: { inputValue: v },
                  });
                }}
              />
            </ImageBox>
          </Bottom>
        </Content>
      </Bottom>
      <BottomButtonWrpper>
        <CommonButton onClick={tempRegister} width="100px" margin="5px">
          一時保存
        </CommonButton>
        <CommonButton onClick={register} disabled={checkSum!==25} width="100px" margin="5px">
          確定
        </CommonButton>
      </BottomButtonWrpper>
    </Container>
  );
};

const BottomButtonWrpper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
`;
const Left = styled.div`
  /* border: solid 1px; */
  width: 50%;
  /* padding: 10px; */
  margin-right: 10px;
`;

const Right = styled.div`
  /* border: solid 1px; */
  width: 50%;
  /* padding: 10px; */
  margin-left: 10px;
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
`;

const ImageBox = styled.div`
  width: 12.5%;
  /* border: solid 1px; */
  height: ${(100 * 4) / 3}px;
`;
