import { api, ReqAndRes } from "../../api";
import { useEffect, useState } from "react";
import { BadNews } from "../../components/BadNews";
import { Link } from "react-router-dom";
import { CommonButton } from "../../utils/Button";
import { URLForRegidenceUser } from "../../utils/typeAndConfig";
import { QRCodeCanvas } from "qrcode.react";
import { useQRCode } from 'next-qrcode';



export const Top = () => {
  const [badnews, setBadnews] = useState<
    ReqAndRes["GET usages/bad-news/for-owner/"]["res"]["data"]["results"]
  >([]);

  const [ownerId, setOwnerId] = useState<string>();

  const [isExistExaminatationUser, setIsExistExaminatationUser] =
    useState(false);
  useEffect(() => {
    (async () => {
      await api("GET usages/bad-news/for-owner/", { is_owner_check: false })
        .then((res) => {
          console.log(res);
          setBadnews(res.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
    getExaminationList();
  }, []);

  const getExaminationList = async () => {
    api("GET users/examination_users/", { is_examinated: 0 })
      .then((res) => {
        setIsExistExaminatationUser(res.data.count > 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //   ２回呼んでるし良くない。
  useEffect(() => {
    (async () => {
      await api("GET users/owner/me/", null)
        .then((res) => {
          console.log(res);
          setOwnerId(res.data.id);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  },[]);
  const { Canvas } = useQRCode();

  return (
    <div>
      {/* <h1>トップページ</h1> */}
      <h2>住民用招待用リンク</h2>
      {ownerId && <>{URLForRegidenceUser + "?owner=" + ownerId}
      <br/><Canvas
    text={URLForRegidenceUser + "?owner=" + ownerId} 
    options={{ // ここの要素は省略可能。ご利用は計画的に。
      type: 'image/webp', // 出力フォーマット。 pngかjpegかwebpから選べる
      quality: 0.95, // クオリティ。 0〜１で1に近い方が綺麗。
      level: 'M', // 補正レベル。 low(L)、medium（Ｍ）、quartile(Ｑ)、high(H)から選べる。
      margin: 3, // クワイエットゾーンの広さを決める。
      scale: 4, // ドット1個につき何px使うかの指標。
      width: 200, // 出力画像の幅の指定。(収まらなかったら無視する)
      color: { // RGBAで色を決める。
        dark: '#000000',
        light: '##ffffff',
      },
    }}
  />
    
      </>}
      {/* 共通化するべき。。。 */}
      <h2>完了していない審査の有無</h2>
      {isExistExaminatationUser ? (
        <>
          <Link to="/examination/users/">
            <h3>あり</h3>
            <CommonButton>審査一覧を確認</CommonButton>
          </Link>
        </>
      ) : (
        "なし"
      )}
      <h2>確認していない報告一覧</h2>
      {!!badnews && <BadNews badnews={badnews} />}
      {/* <h2>忘れ物など一覧(確認前)</h2> */}
    </div>
  );
};
